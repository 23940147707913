import React from 'react';
import { NavLink } from 'react-router-dom';

const SettingsNav = () => {
    const handleDisabledLinkClick = (e) => {
        e.preventDefault(); // Prevent navigation for disabled links
    };

    return (
        <>
            <nav className="nav nav-borders">
                {/* Uncomment or adjust based on your application's structure */}
                {/* <NavLink className="nav-link" exact to="/dashboard/settings/account" activeClassName="active">Account</NavLink> */}
                <NavLink className="nav-link" to="/settings/marketplaces" activeClassName="active">Marketplaces</NavLink>
                {/* Disable Billing NavLink - Removed activeClassName since it's always disabled */}
                <a href="#" className="nav-link disabled" onClick={handleDisabledLinkClick} title="Coming Soon">Billing</a>
                {/* Disable Importing NavLink - Removed activeClassName since it's always disabled */}
                <a href="#" className="nav-link disabled" onClick={handleDisabledLinkClick} title="Coming Soon">Importing</a>
            </nav>
            <hr className="mt-0 mb-4" />
        </>
    );
}

export default SettingsNav;
