// utils/saveDatabase.js

import { listingAPI } from "../api";
import { createMarketplaceUpdate } from "./updateStatus";

async function saveDataToDatabase(token, marketplaceData, formData, marketplaceListingId, status){
    const { pictures: marketplacePictures, ...restMarketplaceData } = marketplaceData;
    const { pictures: formPictures, ...restFormData } = formData;

    const updatedMarketplacePictures = marketplacePictures.map(picture => {
        if (picture._id && picture._id.startsWith('temp')) {
          return { imageUrl: picture.imageUrl };
        }
        return picture;
    });

    const updatedFormPictures = formPictures.map(picture => {
        if (picture._id && picture._id.startsWith('temp')) {
          return { imageUrl: picture.imageUrl };
        }
        return picture;
    });

    const updateMarketplaceData = {
        ...restMarketplaceData,
        marketplaceListingId: marketplaceListingId,
        status: status,
        pictures: updatedMarketplacePictures
    };

    const updatedFormData = createMarketplaceUpdate({...restFormData, pictures: updatedFormPictures}, marketplaceData.marketplace, status, marketplaceListingId);

    console.log('updateMarketplaceData - ', updateMarketplaceData);
    console.log('updatedFormData - ', updatedFormData);

    let responseData;
    if(marketplaceData.id){
        responseData = await listingAPI.updateMarketplaceListing(token, updateMarketplaceData, updatedFormData);
    } else {
        responseData = await listingAPI.createMarketplaceListing(token, updateMarketplaceData, updatedFormData);
    }

    return responseData;
}

export { saveDataToDatabase };
