import React, { useEffect, useState } from 'react';
import { useUserAuth } from '../../../context/UserAuthContext';
import Footer from '../../Helper/Footer';
import SettingsNav from './SettingsNav';
import { sharerAPI } from '../../../api';

const SettingsSetup = () => {
  const { user } = useUserAuth();
  const [authData, setAuthData] = useState({});
  const [authDataLoading, setAuthDataLoading] = useState(false);
  const [authForm, setAuthForm] = useState({});
  const [authFormLoading, setAuthFormLoading] = useState(false);
  const [authResponse, setAuthResponse] = useState({});
  const [shareLoading, setShareLoading] = useState(false);
  const [shareResponse, setShareResponse] = useState({});
  const [sharerLogs, setSharerLogs] = useState([]);
  const [sharerLogsLoading, setSharerLogsLoading] = useState(false);
  const [itemId, setItemId] = useState('');

    useEffect(() => {
        const fetchAuthData = async () => {
            try {
                setAuthDataLoading(true);
                setSharerLogsLoading(true);
                const token = await user.getIdToken(true);
                const authResponse = await sharerAPI.getPoshmarkAuth(token);
                const sharerLogsResponse = await sharerAPI.getSharerLogs(token);
                console.log('authResponse - ', authResponse);
                setAuthData(authResponse);
                setSharerLogs(sharerLogsResponse);
            } catch (error) {
                console.error('An error occurred while getting total imported:', error);
            } finally {
                setAuthDataLoading(false);
                setSharerLogsLoading(false);
            }
        };


        if(user){
            fetchAuthData();
            setSharerLogs();
        }
    }, [user]);

    

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log('form Data - ', authForm);

        try {
            setAuthFormLoading(true);
            const token = await user.getIdToken(true);
            const authResponse = await sharerAPI.submitPoshmarkAuth(token, authForm.username, authForm.password);
            console.log('authResponse - ', authResponse);
            setAuthResponse(authResponse);
        } catch (error) {
            console.error('An error occurred while getting total imported:', error);
        } finally {
            setAuthFormLoading(false);
        }

    };

    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setAuthForm((prevForm) => ({
          ...prevForm,
          [id]: value,
        }));
    };

    const handleStartSharing = async () => {
        // Your function logic here
        console.log('Start Sharing button clicked');
        try {
            setShareLoading(true);
            const token = await user.getIdToken(true);
            const shareResponse = await sharerAPI.startSharing(token);
            console.log('shareResponse - ', shareResponse);
            setShareResponse(shareResponse);
        } catch (error) {
            console.error('An error occurred while getting total imported:', error);
        } finally {
            setShareLoading(false);
        }
    };

    const handleReListSubmit = async (event) => {
        event.preventDefault();
        console.log('Re-List Item ID:', itemId);
        if (!itemId || itemId.length < 3) {
            console.log('Invalid Item ID');
            return;
        }
        
        const relistResponse = await sharerAPI.startRelist(itemId);
        console.log('relistResponse - ', relistResponse);
    };
      
    

  return (
    <>
    <main>
      <div className="container-xl px-4 mt-4">
        <SettingsNav />

          <div className="row">
            <div className="col-lg-4">
                <div className="card mb-4">
                <div className="card-header">Settings Newp</div>
                <div className="card-body">
                    {authDataLoading ? (
                        <div>Loading...</div>
                    ) : (
                        <div>{authData ? JSON.stringify(authData) : 'No data available'}</div>
                    )}
                </div>
                </div>
            </div>
            <div className="col-lg-8">
                <div className="card mb-4">
                <div className="card-header">Settings Newp</div>
                <div className="card-body">
                    {authDataLoading ? (
                        <div>Loading...</div>
                    ) : (
                        (authData && Object.keys(authData).length === 0) || (authData && authData.connected === false) ? (
                        <form onSubmit={handleSubmit}>
                            <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="username">Username</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="username"
                                    placeholder="Username"
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="password">Password</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    id="password"
                                    placeholder="Password"
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            </div>
                            <button type="submit" className="btn btn-primary" disabled={authFormLoading || (authResponse && Object.keys(authResponse).length > 0)}>
                                {authFormLoading ? 'Loading...' : (authResponse && Object.keys(authResponse).length > 0 ? 'Submitted' : 'Submit')}
                            </button>

                        </form>
                        ) : (
                            <>
                                <div>{JSON.stringify(authData)}</div>
                                <button
                                    type="button"
                                    className="btn btn-primary mt-3"
                                    onClick={handleStartSharing}
                                    disabled={shareLoading || (shareResponse && Object.keys(shareResponse).length > 0)}
                                >
                                    {shareLoading ? 'Loading...' : (shareResponse && Object.keys(shareResponse).length > 0 ? 'Submitted' : 'Start Sharing')}
                                </button>
                                {shareResponse && Object.keys(shareResponse).length > 0 && (
                                    <div className="mt-3">{JSON.stringify(shareResponse)}</div>
                                )}
                            </>
                        )
                    )}




                </div>
                </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4"></div>
            <div className="col-lg-8">
                <div className="card mb-4">
                <div className="card-header">Re-List</div>
                <div className="card-body">
                    <form onSubmit={handleReListSubmit}>
                    <div className="form-row align-items-center">
                        <div className="col-auto">
                        <label htmlFor="itemId" className="sr-only">Item ID</label>
                        <input
                            type="text"
                            className="form-control mb-2"
                            id="itemId"
                            value={itemId}
                            onChange={(e) => setItemId(e.target.value)}
                            placeholder="Enter Item ID"
                            required
                        />
                        </div>
                        <div className="col-auto">
                        <button type="submit" className="btn btn-secondary mb-2">Submit</button>
                        </div>
                    </div>
                    </form>
                </div>
                </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4"></div>
            <div className="col-lg-8">
                <div className="card mb-4">
                    <div className="card-header">Sharer Logs</div>
                    <div className="card-body" style={{ maxHeight: '50vh', overflowY: 'scroll' }}>
                        {sharerLogsLoading ? (
                            <div>Loading...</div>
                        ) : (
                            <>
                                {sharerLogs && sharerLogs.length > 0 ? (
                                    sharerLogs.map((log, index) => (
                                        <div key={index} className="card mb-2">
                                            <div className="card-body">
                                                <div><strong>ID:</strong> {log._id}</div>
                                                <div><strong>Firebase UID:</strong> {log.firebaseuid}</div>
                                                <div><strong>Poshmark Auth ID:</strong> {log.poshmarkAuthId}</div>
                                                <div><strong>Time Started:</strong> {log.timeStarted}</div>
                                                <div><strong>Error:</strong> {log.error ? 'Yes' : 'No'}</div>
                                                <div><strong>Date Updated:</strong> {log.dateUpdated}</div>
                                                <div><strong>Date Added:</strong> {log.dateAdded}</div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div>No Sharer Logs</div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
           </div>

      </div>
    </main>
    <Footer />
    </>
  );
};

export default SettingsSetup;

