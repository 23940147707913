import React, { useState, useEffect } from 'react';
import { listingAPI } from '../../api';
import { useUserAuth } from '../../context/UserAuthContext';
import { Search, XCircle, PlusSquare, ThreeDots, Trash } from 'react-bootstrap-icons';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import ImportModal from './ImportModal';
import Pagination from './Pagination';
import ProductRowSkeleton from './ProductRowSkeleton';

const Header = ({searchTerm, setSearchTerm, handleSearchChange}) => {
  const navigate = useNavigate();

  const handleClearSearch = () => {
    setSearchTerm(''); 
  };

  const handleStartListing = () => {
    navigate(`/listing/new${searchTerm ? `?term=${searchTerm}` : ''}`);
  };

  return (
    <header className="page-header page-header-dark bg-gradient-primary-to-secondary mb-4">
        <div className="container-xl px-4">
            <div className="page-header-content pt-4">
                <div className="row align-items-center justify-content-between">
                    <div className="col-auto mt-4">
                        <h1 className="page-header-title">
                            <div className="page-header-icon"></div>
                            Listings
                        </h1>
                        <div className="page-header-subtitle">
                            Search to find existing listings or to start a new one.
                        </div>
                    </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="page-header-search mt-4">
                        <div className="input-group input-group-joined">
                          <input
                              type="text"
                              className="form-control"
                              value={searchTerm}
                              onChange={handleSearchChange}
                              placeholder="Search..."
                              aria-label="Search" autoFocus
                          />
                          <span className="input-group-text" style={{cursor: 'pointer', fontSize: '1.5rem'}}>
                            {searchTerm && (
                              <XCircle 
                                onClick={handleClearSearch} 
                                style={{marginRight: '10px', cursor: 'pointer'}}
                                fill="#ff6b6b"
                              />
                            )}
                            <Search />
                          </span>
                        </div>
                    </div>
                  </div>
                  <div className="col-3">

                  <div 
                    className="card bg-primary text-white h-100" 
                    onClick={handleStartListing} 
                    style={{ cursor: 'pointer' }}
                  >
                    <div className="card-body">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="me-3">
                          <div className="text-lg fw-bold">
                              {searchTerm ? 'Start With Search' : 'Start Listing'}
                          </div>
                        </div>
                        <PlusSquare color="white" size={24} className="text-white-50" />
                      </div>
                    </div>
                  </div>

                  </div>
                  <div className="col-3"></div>

                </div>

            </div>
        </div>
    </header>
  );
};

const DashboardNavigation = ({ activeFilter, handleFilterChange, marketplaceFilters, setMarketplaceFilters }) => {
  const navigate = useNavigate();

  const handleNavLinkClick = (filter) => {
    handleFilterChange(filter);
    navigate(`?filter=${filter}`);
  };

  const handleMarketplaceFilterChange = (marketplace) => {
    const currentState = marketplaceFilters[marketplace];
    const newState = currentState === 'none' ? 'include' : currentState === 'include' ? 'exclude' : 'none';
    setMarketplaceFilters({
      ...marketplaceFilters,
      [marketplace]: newState
    });
  };

  const marketplaceOptionsDefault = {
    eBay: { displayName: "eBay", color: '#2937f0' },
    Poshmark: { displayName: "Poshmark", color: '#7f0353' },
    Mercari: { displayName: "Mercari", color: '#5356ee' }
  };
  

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px' }}>
        {/* Navigation Links */}
        <nav className="nav nav-borders">
          <Link 
            to="?filter=all"
            className={`nav-link ms-0 ${activeFilter === 'all' ? 'active' : ''}`} 
            onClick={() => handleNavLinkClick('all')}
          >All</Link>
          <Link 
            to="?filter=draft"
            className={`nav-link ms-0 ${activeFilter === 'draft' ? 'active' : ''}`} 
            onClick={() => handleNavLinkClick('draft')}
          >Drafts</Link>
          <Link 
            to="?filter=active"
            className={`nav-link ${activeFilter === 'active' ? 'active' : ''}`} 
            onClick={() => handleNavLinkClick('active')}
          >Active</Link>
          <Link 
            to="?filter=sold"
            className={`nav-link ${activeFilter === 'sold' ? 'active' : ''}`} 
            onClick={() => handleNavLinkClick('sold')}
          >Sold</Link>
        </nav>
  
        {/* Marketplace Buttons */}
        <div>
          {Object.keys(marketplaceFilters).map((marketplace) => (
            <button
              key={marketplace}
              className={`btn ${marketplaceFilters[marketplace] === 'include' ? 'btn-primary' : marketplaceFilters[marketplace] === 'exclude' ? 'btn-danger' : 'btn-outline-primary'}`}
              style={{
                backgroundColor: marketplaceFilters[marketplace] === 'include' ? marketplaceOptionsDefault[marketplace].color : 
                                marketplaceFilters[marketplace] === 'exclude' ? '#dc3545' : 'transparent',
                color: marketplaceFilters[marketplace] === 'none' ? marketplaceOptionsDefault[marketplace].color : 'white',
                borderColor: marketplaceFilters[marketplace] === 'none' ? marketplaceOptionsDefault[marketplace].color : 'transparent',
                cursor: 'pointer',
                margin: '0 3px' // Adds some spacing between buttons
              }}
              onClick={() => handleMarketplaceFilterChange(marketplace)}
            >
              {marketplaceFilters[marketplace] === 'exclude' ? `Not ${marketplaceOptionsDefault[marketplace].displayName}` :
              marketplaceOptionsDefault[marketplace].displayName}
            </button>
          ))}
        </div>
      </div>
    </>
  );
  
};

const MarketplaceButton = ({ marketplace, index }) => {
    if (marketplace.name === 'General') return null;

    const marketplaceConvert = {
      eBay: {
          color: '#2937f0',
          urlPrefix: 'https://ebay.com/itm/'
      },
      Poshmark: {
          color: '#7f0353', 
          urlPrefix: 'https://poshmark.com/listing/'
      },
      Mercari: {
          color: '#5356ee', 
          urlPrefix: 'https://mercari.com/us/item/'
      }
    };

    const config = marketplaceConvert[marketplace.name];
    const defaultStyle = {
        backgroundColor: '#6c757d',
        borderColor: '#6c757d'
    };

    const style = config ? {
        backgroundColor: config.color,
        borderColor: config.color,
        color: 'white',
        marginRight: '3px'
    } : defaultStyle;

    // Additional style adjustments for no marketplace listing
    const buttonStyle = !marketplace.marketplaceListing ? {
        ...style,
        backgroundColor: 'transparent',
        color: style.borderColor
    } : style;

    return (
        <>
            {config && marketplace.marketplaceListing ? (
                <a
                    key={index}
                    href={`${config.urlPrefix}${marketplace.marketplaceListing}`}
                    className="btn btn-sm"
                    style={buttonStyle}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(e) => e.stopPropagation()}
                >
                    {marketplace.name}
                </a>
            ) : (
                <button
                    key={index}
                    className="btn btn-sm"
                    style={buttonStyle}
                    disabled={!marketplace.marketplaceListing}
                    title={!marketplace.marketplaceListing ? 'Draft' : ''}
                >
                    {marketplace.name}
                </button>
            )}
        </>
    );
}

const ProductRow = ({ id, icon, sku, title, price, status, marketplaces }) => {
  //TODO: Mark as Sold action;

  const navigate = useNavigate();
  const { user } = useUserAuth();
  const [isDeleted, setIsDeleted] = useState(false);


  const handleProductClick = (event) => {
    if (event.metaKey || event.ctrlKey) {
      // Opens in a new tab if Cmd or Ctrl is pressed
      window.open(`/listing/${id}`, '_blank');
    } else {
      // Normal navigation
      navigate(`/listing/${id}`);
    }
  };
  

  const handleDeleteClick = async () => {
    console.log(`Deleting product with id ${id}`);
    try {
      const token = await user.getIdToken(true);
      const deleteResponse = await listingAPI.deleteProduct(token, id);
      setIsDeleted(true);
    } catch (error) {
        console.error('Error deleting item:', error);
    }
  }

  if (isDeleted) {
    return null; // Don't render anything if the product is deleted
  }

  return (
    <div className="card" style={{ borderRadius: '10px', margin: '10px', position: 'relative', cursor: 'pointer' }} onClick={handleProductClick}>
      <div className="dropdown no-caret" style={{ position: 'absolute', top: '10px', right: '10px' }}>
        <button className="btn btn-transparent-dark btn-icon dropdown-toggle" id="dropdownMenuButton" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={(e) => e.stopPropagation()}>
          <ThreeDots />
        </button>
        <div className="dropdown-menu dropdown-menu-end animated--fade-in-up" aria-labelledby="dropdownMenuButton">
          <button className="dropdown-item" type="button" onClick={(e) => {e.stopPropagation(); handleDeleteClick();  }}>
            <Trash className="mr-2" /> Delete
          </button>
        </div>
      </div>

      <div className="row no-gutters" style={{ height: '100%' }}>
        <div className="col-2">
          <img className="img-fluid" src={icon} alt={title} style={{ borderRadius: '10px 0 0 10px' }} />
        </div>
        <div className="col-8 d-flex flex-column">
          <div className="ml-4 mt-4 flex-grow-1">
            <h5 className="card-title">{title}</h5>
            <div className="d-flex">
              <p className="card-text product-details">SKU: {sku}</p>
              <p className="card-text">Price: {price}</p>
            </div>
            <p className="card-text" style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              Status: {`${status[0].toUpperCase()}${status.slice(1)}`}

              <span style={{ marginRight: '3px' }}></span>

              {marketplaces && marketplaces.length > 0 && marketplaces.map((marketplace, index) => (
                <MarketplaceButton key={index} marketplace={marketplace} index={index} />
              ))}


            </p>

          </div>
          <div className="d-flex marketplaces ml-4 mb-4">
          </div>
        </div>
      </div>
    </div>
  );
};

const Dashboard = () => {
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [activeFilter, setActiveFilter] = useState('all');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const [nextPage, setNextPage] = useState(false);
  const [marketplaceFilters, setMarketplaceFilters] = useState({
    Mercari: 'none',  // Possible values: 'include', 'exclude', 'none'
    eBay: 'none',
    Poshmark: 'none'  
  });
  
  const { user } = useUserAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const filter = params.get('filter');
    const page = params.get('page');
    const term = params.get('term');
    if (filter) {
      setActiveFilter(filter);
    }
    if (page) {
      setCurrentPage(Number(page));
    }
    if (term) {
      setSearchTerm(term);
    }
  }, [location.search]);

  // Update the URL whenever activeFilter or currentPage changes
  useEffect(() => {
    const params = new URLSearchParams();
  
    // Set filter param
    if (activeFilter.trim() && activeFilter.trim() !== 'all') {
      params.set('filter', activeFilter);
    }
  
    // Set page param
    if (currentPage > 1) {
      params.set('page', String(currentPage));
    }else{
      params.delete('page');
    }
  
    // Set term param
    if (searchTerm.trim()) {
      params.set('term', searchTerm);
    }
  
    // Navigate to the new URL
    navigate(params.toString() ? `?${params.toString()}` : '.', { replace: true });
    window.scrollTo({
      top: 0,
      behavior: 'smooth' 
    });
  }, [navigate, activeFilter, currentPage, searchTerm]);

  const handleFilterChange = (newFilter) => {
    setActiveFilter(newFilter);
    setCurrentPage(1);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debouncedValue;
  }


  
  const debouncedSearchTerm = useDebounce(searchTerm, 300); 

  const formatMarketplaceFilters = () => {
    const filters = { include: [], exclude: [] };
    Object.entries(marketplaceFilters).forEach(([marketplace, filterType]) => {
      if (filterType === 'include') {
        filters.include.push(marketplace);
      } else if (filterType === 'exclude') {
        filters.exclude.push(marketplace);
      }
    });
    return filters;
  };  

  useEffect(() => {

    const fetchData = async () => {
        if (user) {
            try {
                setIsLoading(true);
                const token = await user.getIdToken(true);

                const marketplaceFilters = formatMarketplaceFilters();
                const params = {
                    status: activeFilter.toLowerCase() === 'all' ? null : activeFilter.toLowerCase(),
                    page: currentPage,
                    term: debouncedSearchTerm,
                    marketplaces: marketplaceFilters
                };

                // Fetch data from the API
                const data = await listingAPI.readListings(token, params);
                console.log('data fetchProducts - ', data);

                // Update state with fetched data
                setProducts(data.items);
                setNextPage(data.nextPage);
                setTotalResults(data.totalResults);
                setIsLoading(false);
            } catch (error) {
                console.error('An error occurred while fetching products:', error);
                setIsLoading(false);
            }
        }
    };

    fetchData();
  }, [user, activeFilter, currentPage, debouncedSearchTerm, marketplaceFilters]);



  return (
    <div>
        <Header 
          searchTerm={searchTerm} 
          setSearchTerm={setSearchTerm}
          handleSearchChange={handleSearchChange}
        />
        <div className="container-xl px-4">
        <ImportModal />
        <DashboardNavigation 
          activeFilter={activeFilter}
          handleFilterChange={handleFilterChange}
          marketplaceFilters={marketplaceFilters}
          setMarketplaceFilters={setMarketplaceFilters}
        />
        {isLoading
          ? Array.from({ length: 5 }).map((_, index) => <ProductRowSkeleton key={index} />)
          : products.map((product, index) => (
            <ProductRow
              id={product._id}  
              key={index}
              icon={product.pictures?.[0]?.imageUrl ? product.pictures[0].imageUrl : 'https://firebasestorage.googleapis.com/v0/b/thriftallday-37c6b.appspot.com/o/site%2Fplaceholder2.png?alt=media'}
              sku={product.sku}
              title={product.title}
              price={product.price}
              status={product.status}
              quantity={product.quantity}
              marketplaces={product.marketplaces}
              activeFilter={activeFilter}
            />
          ))
        }

        {products.length === 0 && !isLoading ? (
          <div className="card mb-4 mt-3">
            <div className="card-body py-5">
              <div className="d-flex flex-column justify-content-center">
                <div className="text-center px-0 px-lg-5">
                  <h5>No results found.</h5>
                  {activeFilter !== 'draft' && (
                    <p className="mb-4">
                      {searchTerm ? `No results for search term "${searchTerm}"` : 'No products imported.'}
                    </p>
                  )}

                  <Link className="btn btn-primary mr-2 mt-2" to="/listing/new">Start a New Listing</Link>

                  {searchTerm ? 
                    <Link 
                        className="btn btn-primary mr-2 mt-2" 
                        to={`/new?term=${encodeURIComponent(searchTerm)}`}
                        style={{ marginLeft: '3px' }}>Start Listing With Search
                    </Link>
                  : null }

                  <button className="btn btn-secondary mr-2 mt-2" style={{marginLeft: '5px'}} onClick={() => window.location.reload()}>Refresh</button>


                </div>
              </div>
            </div>
          </div>
        ) : (
          <Pagination 
            handlePageChange={handlePageChange}
            currentPage={currentPage}
            nextPage={nextPage}
            totalResults={totalResults}
          />
        )}

        </div>
    </div>
  );
};
  
  export default Dashboard;