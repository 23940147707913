import React, { useState } from 'react';

const SettingsCollapsableCard = ({ 
    cardTitle,
    idFragment,
    children,
    theme = 'default',
    updateSettings,
    settingsDataSaved,
    submitLoading,
}) => {
    // Use internal state for collapse control
    const [isCollapsed, setIsCollapsed] = useState(false);

    // Toggle collapse state on user interaction
    const toggleCollapse = () => setIsCollapsed(!isCollapsed);

    // Construct unique IDs for accessibility and targeting
    const cardId = `collapse${idFragment}`;
    const headerId = `${idFragment}-header`;

    // Define styles based on the theme
    const styles = {
        ebay: {
            header: { backgroundColor: '#2937f0', color: 'white' },
            card: { border: '3px solid rgb(41, 55, 240)' }
        },
        poshmark: {
            header: { backgroundColor: '#7f0353', color: 'white' },
            card: { border: '3px solid rgb(127, 3, 83)' }
        },
        mercari: {
            header: { backgroundColor: '#5356ee', color: 'white' },
            card: { border: '3px solid rgb(83, 86, 238)' }
        },
        default: {},
    };

    // Determine the current styles based on the selected theme
    const currentStyle = styles[theme] || styles.default;

    return (
        <div className="card card-collapsable mb-3" id={headerId} style={currentStyle.card}>
            <div 
                className="card-header" 
                style={{...currentStyle.header, borderRadius: '0px'}} 
                role="button" 
                onClick={toggleCollapse}
                data-bs-toggle="collapse" 
                data-bs-target={`#${cardId}`} 
                aria-expanded={!isCollapsed} 
                aria-controls={cardId}
            >
                <div>
                    {cardTitle}
                </div>
                <div className="card-collapsable-arrow">
                    <i className={`fas ${isCollapsed ? 'fa-chevron-right' : 'fa-chevron-down'}`}></i>
                </div>
            </div>

            <div className={`collapse ${isCollapsed ? '' : 'show'}`} id={cardId}>
                <div className="card-body">
                    {children}
                </div>
            </div>

            {!isCollapsed && (
                <div className="card-footer">
                    <div className="text-end">

                    {settingsDataSaved ? (
                        <>
                            <button className="btn btn-success btn-sm" disabled style={{ marginRight: '5px' }}>
                                Saved
                            </button>
                            <button className="btn btn-primary btn-sm" style={{ marginRight: '5px' }} onClick={updateSettings}>
                                {submitLoading ? (
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                ) : (
                                    "Update Settings"
                                )}
                            </button>
                        </>
                    ) : (
                        <button className="btn btn-primary btn-sm" style={{ marginRight: '5px' }} onClick={updateSettings}>
                            {submitLoading ? (
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            ) : (
                                "Save"
                            )}
                        </button>
                    )}

                    </div>
                </div>
            )}
        </div>
    );
};

export default SettingsCollapsableCard;
