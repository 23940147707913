import React, { useState, useEffect, useRef } from 'react';
import { PencilSquare } from 'react-bootstrap-icons';
import { useUserAuth } from '../../context/UserAuthContext';
import { listingAPI, templateAPI, settingAPI } from '../../api';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid'; 
import { useExtension } from '../../context/ExtensionContext';
import { updateMarketplaceStatus } from '../../utils/updateStatus';

import GeneralListing from './FormSimple/GeneralListing';
import CrossListEbay from './CrossListEbay';
import CrossListPoshmark from './CrossListPoshmark';
import CrossListMercari from './CrossListMercari';
import MarketplaceSideNav from './MarketplaceSideNav';


const Header = () => {
    return (
        <header className="page-header page-header-light bg-light mb-0">
            <div className="container-xl px-3">
                <div className="page-header-content pt-3">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-auto mt-3">
                            <h1 className="page-header-title">
                                <div className="page-header-icon">
                                    <PencilSquare />
                                </div>
                                AllButNew Cross-Listing
                            </h1>
                            <div className="page-header-subtitle">
                                We help you list fast. 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

const ListingModeSelector = () => {
    //TODO: change write to form, load in custom options
    //trigger changes to form by change
    const [selectedMode, setSelectedMode] = useState('Clothing');
    const handleModeChange = mode => {
        setSelectedMode(mode);
    };

    const listingModes = ['Clothing'];

    return (
        <div className="card card-header-actions mb-2">
            <div className="card-header">
                <div className="row align-items-center">
                    <div className="col-auto">
                        <label className="form-label mb-0">Listing Mode</label>
                    </div>
                    <div className="col">
                        <div className="d-flex overflow-auto">
                            {listingModes.map(mode => (
                                <button
                                    key={mode}
                                    type="button"
                                    className={`btn ${selectedMode === mode ? 'btn-primary' : 'btn-outline-primary'} me-2`}
                                    onClick={() => handleModeChange(mode)}
                                >
                                    {mode}
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );    
}

const itemSpecificDefaults = {
    categorySpecifics: [
        { common: true, name: 'Brand', rules: {isSingle: true, isFreeText: true, required: true}, values: [] },
        { common: true, name: 'Size', rules: {isSingle: true, isFreeText: true, required: true}, values: ['XS', 'S', 'M', 'L', 'XL', '2XL', '3XL'] },
        { common: true, name: 'Size Type', rules: {isSingle: true, isFreeText: false, required: true}, values: ['Regular', 'Big & Tall'] },
        { common: true, name: 'Color', rules: {isSingle: true, isFreeText: true, required: true}, values: ['Blue', 'Black', 'Multicolor', 'White', 'Gray', 'Red', 'Green', 'Brown', 'Orange', 'Beige', 'Yellow', 'Purple', 'Pink'] },
        { common: true, name: 'Sleeve Length', rules: {isSingle: true, isFreeText: true, required: true}, values: ['Sleeveless', 'Short Sleeve', '3/4 Sleeve', 'Long Sleeve'] }
    ]
}

const CrossListForm = ({ isNew }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const { extInstalled, sendMessageToExtension } = useExtension();

    const { user } = useUserAuth();
    const [formData, setFormData] = useState({quantity: 1});
    const [ebayData, setEbayData] = useState({ marketplace: 'eBay' });
    const [poshmarkData, setPoshmarkData] = useState({ marketplace: 'Poshmark' });
    const [mercariData, setMercariData] = useState({ marketplace: 'Mercari' });
    const [imageData, setImageData] = useState([]);
    const [templates, setTemplates] = useState({});
    const [activeMarketplace, setActiveMarketplace] = useState('General');
    const [listingDefaults, setListingDefaults] = useState({});

    const [categoryData, setCategoryData] = useState(itemSpecificDefaults); //eBay Category (load when category in General Loaded, faster for later)
    const [categoryDataFetched, setCategoryDataFetched] = useState(null); //for eBay
    const requestIdRef = useRef(formData.utils?.tempRequestId);

    const [extensionChecked, setExtensionChecked] = useState(false);

    //TODO: once categoryData is fetched, look for size chart
    
    //TODO: log to ensure updates are happening; with ID then update display / submit buttons (update) 
    //TODO: on Node API, generalListing should have a marketplaces Array;
    //ACTION: update formData on draft/submit from eBay

    // DO NOT load in Listing Default if mode is 'loaded'

    useEffect(() => {
        console.log('Extension installed status:', extInstalled);
        if (!extInstalled && !extensionChecked) {
            const requestId = `check_install_${Date.now()}`;
            sendMessageToExtension({ action: 'checkInstallation', data: null, formData: null, token: null, requestId });
            setExtensionChecked(true);
        }
    }, [extInstalled, sendMessageToExtension]);
    

    useEffect(() => {
        //NOTE: submitMarketplaceExt, useEffect adding tempGeneralRequestId, & this are used if user starts New Listing, but pending ext submissions are happening
        requestIdRef.current = formData.utils?.tempRequestId;
        console.log('formData.utils?.tempRequestId - ', formData.utils?.tempRequestId);
        console.log('tempRequestId requestIdRef.current - ', requestIdRef.current);
    }, [formData.utils?.tempRequestId]);

    const submitMarketplaceExt = async(marketplaceData, actionName) => {
        const token = await user.getIdToken(true);
        const currentRequestId = requestIdRef.current + marketplaceData.marketplace;
        console.log('submitMarketplaceExt requestIdRef.current - ', requestIdRef.current)

        const response = await sendMessageToExtension({
            action: actionName,
            data: marketplaceData,
            formData: formData,
            token: token,
            requestId: currentRequestId
        });

        if(response.requestId === (requestIdRef.current + marketplaceData.marketplace)){
            console.log('MATCH TO REQUEST IDS FOUND HERE response.data - ', response.data);

            if (response && response.data && response.data.error === 'LOGGED_OUT'){
                updateMarketplaceStatus(marketplaceData.marketplace, 'error', setFormData);
                return { 
                    error: true, 
                    alertMessage: `You are not logged into ${marketplaceData.marketplace}. Please log in on another tab and try again.`, 
                    alertType: 'danger'
                }
            }else if (response && response.responseData && response.data?.listingId) {

                const responseData = response.responseData;

                const updatedMarketplaceData = {
                    ...marketplaceData,
                    marketplaceListingId: response.data.listingId,
                    status: 'active'
                };
                updateMarketplaceStatus(marketplaceData.marketplace, 'listed', setFormData, response.data.listingId, true);

                return {
                    data: {
                        marketplaceListingData: updatedMarketplaceData, 
                        ...responseData 
                    },
                    alertMessage: `${marketplaceData.marketplace} listing created successfully!`,
                    alertType: 'success'
                }
                
            } else {
                return {
                    error: true,
                    alertMessage: 'Failed to receive listing ID from the extension',
                    alertType: 'error'
                }
            }
        }else{
            return { newListing: true }
        }
    }

    useEffect(() => {
        const tempGeneralRequestId = uuidv4();
        console.log('tempGeneralRequestId - ', tempGeneralRequestId);

        setFormData(prevData => ({
            ...prevData,
            utils: {
                ...prevData.utils,
                tempRequestId: tempGeneralRequestId
            }
        }));
    }, []);

    useEffect(() => {

        if (formData.utils?.mode === 'reloadPending') {
            console.log('Reload mode activated');
            setActiveMarketplace('General');

            const newTempGeneralRequestId = uuidv4();
            console.log('NEW FORM UPDATE newTempGeneralRequestId - ', newTempGeneralRequestId);
            setFormData({quantity: 1, utils: {mode: 'reload', tempRequestId: newTempGeneralRequestId}});
            setEbayData({ marketplace: 'eBay' });

            setPoshmarkData({ marketplace: 'Poshmark' });
            setMercariData({ marketplace: 'Mercari' });
            setImageData([]);
            setTemplates({});
            setCategoryData(itemSpecificDefaults);
            setCategoryDataFetched(false);
            updateFormDataAndEbayData(listingDefaults, setFormData, setEbayData); //reset listing defaults

            const searchParams = new URLSearchParams(location.search);
            searchParams.delete('mode');
            navigate({
                pathname: '/listing/new',
                search: searchParams.toString()
            }, { replace: true }); 

            window.scrollTo(0, 0);
        }

    }, [formData.utils?.mode]);

    useEffect(() => {
        const getGeneralListing = async (id) => {
            try {
                const token = await user.getIdToken(true);
                const responseData = await listingAPI.readGeneralListing(token, id);

                console.log('responseData - ', responseData);

                if(responseData.listingData && responseData.message === 'Success!'){
                    if(responseData.listingData?.general?.pictures){ 
                        const transformedData = responseData.listingData?.general?.pictures.map(imageObj => ({
                            blobURL: imageObj.imageUrl,
                            firebaseURL: imageObj.imageUrl,
                            _id: imageObj._id
                        }));
                        setImageData(transformedData);
                    }

                    //TODO: do not use prevFormData, place the data while adding move and { name: 'General', status: 'saved' } 
                    if(responseData.listingData.general){
                        const generalData = responseData.listingData.general;
                        const marketplaces = generalData.marketplaces ? [...generalData.marketplaces] : [];
                        marketplaces.push({ name: 'General', status: 'saved' });
                        setFormData(prevData => ({
                            ...prevData, 
                            ...generalData,
                            utils: {
                                ...prevData.utils, 
                                ...generalData.utils,
                                mode: 'loaded' 
                            },
                            marketplaces: marketplaces
                        }));                        
                    } 

                    if(responseData.listingData.eBay) setEbayData(responseData.listingData.eBay);
                    if(responseData.listingData.Poshmark) setPoshmarkData(responseData.listingData.Poshmark);
                    if(responseData.listingData.Mercari) setMercariData(responseData.listingData.Mercari);
                    
                }else{
                    navigate('/listing/new');
                }

            } catch (error) {
                console.error('Failed to fetch listing data', error);
                navigate('/listing/new');
            }
        };

        if (!isNew && id) {
            getGeneralListing(id)
        }
    }, [id, isNew]);

    useEffect(() => {
        console.log('change to activeMarketplace - ', activeMarketplace);
        window.scrollTo(0, 0);
    }, [activeMarketplace]);

    function formatMarketplaceName(name) {
        if (name === 'ebay') return 'eBay';
        return name.charAt(0).toUpperCase() + name.slice(1);
    }
      
    useEffect(() => {
      // Parse the search query string
      const queryParams = new URLSearchParams(location.search);
      const draftId = queryParams.get('draftId');
      const marketplaceName = queryParams.get('marketplace');

      console.log('location effect - ', marketplaceName)
      if (marketplaceName && marketplaceName.toLowerCase() !== activeMarketplace.toLowerCase()) {
        const formattedName = formatMarketplaceName(marketplaceName);
        setActiveMarketplace(formattedName);
      }

      if (draftId) {
        console.log(`Draft ID found: ${draftId}`);
        fetchDraftDetails(draftId);
      }
    }, [location]);

    const fetchDraftDetails = async (draftId) => {
        try {
            const token = await user.getIdToken(true);
            const draftResult = await listingAPI.getDraft(token, draftId);

            if(draftResult.mode === 'draft'){
                const { _id, ...generalWithoutId } = draftResult.general;
                setFormData(prevFormData => ({
                    ...prevFormData,
                    ...generalWithoutId,
                    utils: {
                        ...prevFormData.utils,
                        generalListingId: _id,
                        imageUploadFinished: true,
                        mode: 'draft'
                    }
                }));

                //TODO: add other marketplaces when they are added;
                if (draftResult.eBay) {
                    const { _id: ebayId, ...eBayWithoutId } = draftResult.eBay;
                    setEbayData(prevEbayData => ({
                        ...prevEbayData,
                        ...eBayWithoutId,
                        utils: {
                            ...prevEbayData.utils,
                            marketplaceListingId: _id,
                            mode: 'draft'
                        }
                    }));
                }
            }

            console.log('draftResult - ', draftResult);
            //TODO: load in related marketplace as well?

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const fetchCategoryData = async () => {
        try {
            const token = await user.getIdToken(true);
            const data = await templateAPI.getCategoryData(formData.categoryId, token);
            setCategoryData(data);
            setCategoryDataFetched(formData.categoryId);
        } catch (error) {
            console.error("Error fetching category data:", error);
        }
    };

    useEffect(() => {
        if (formData.categoryId && categoryDataFetched !== formData.categoryId) {
            console.log('FETCHING category data');
            fetchCategoryData();
        }
    }, [formData.categoryId])

    useEffect(() => {
        //TODO: better handling of when to use and when to override this data; 
        //likely need to update so only use for marketplaces without a listing created for it yet
        //do not run it if all drafts/listings are created (or request a smaller data if only a few)

        const fetchListingDefaults = async () => {
            try {
                const token = await user.getIdToken(true);
                const listingDefaultResult = await settingAPI.getDefaults(token);
                console.log('Listing Default:', listingDefaultResult);
                setListingDefaults(listingDefaultResult);
                updateFormDataAndEbayData(listingDefaultResult, setFormData, setEbayData);

            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        if(!id && Object.keys(listingDefaults).length === 0){
            fetchListingDefaults();
        }
    }, []);

    function updateFormDataAndEbayData(listingDefaultResult, setFormData, setEbayData) {
        // Extract the 'general' and 'ebay' parts of the listingDefaultResult
        const { general, ebay, poshmark, mercari } = listingDefaultResult;
    
        if (general) {
            setFormData(prevFormData => {
                if (prevFormData.utils?.mode === 'loaded' || prevFormData.id) {
                    return prevFormData;
                }
    
                const generalSettings = {};
                if (general.description) generalSettings.description = general.description;
                if (general.locationPostalCode) generalSettings.locationPostalCode = general.locationPostalCode;
                if (general.packageDetails) generalSettings.packageDetails = general.packageDetails;
    
                return {
                    ...prevFormData,
                    ...generalSettings
                };
            });
        }
    
        if (ebay) {
            setEbayData(prevEbayData => {
                // If a draft is saved, do not override
                if (prevEbayData.id) {
                    return prevEbayData;
                }
    
                return {
                    ...prevEbayData,
                    ...ebay
                };
            });
        }

        if(poshmark){
            setPoshmarkData(prevPoshmarkData => {
                if(prevPoshmarkData.id){
                    return prevPoshmarkData;
                }

                return {
                    ...prevPoshmarkData,
                    utils: {
                        ...prevPoshmarkData.utils,
                        ...poshmark
                    }
                }
            })
        }

    }
    
    

    return (
        <div>
            <main>
                <Header />
                <div className="container-xl px-4">
                    <div className="row">
                        <div className="col-lg-3">
                            <MarketplaceSideNav 
                                formDataMarketplaces={formData.marketplaces || []}
                                activeMarketplace={activeMarketplace}
                                setActiveMarketplace={setActiveMarketplace}
                                imageData={imageData}
                                extInstalled={extInstalled}
                            />
                        </div>
                        <div className="col-lg-9">
                            {activeMarketplace === 'General' && (
                                <GeneralListing 
                                    formData={formData}
                                    setFormData={setFormData}
                                    templates={templates}
                                    setTemplates={setTemplates}
                                    imageData={imageData}
                                    setImageData={setImageData}
                                    setEbayData={setEbayData}
                                    setActiveMarketplace={setActiveMarketplace}
                                />
                            )}

                            {activeMarketplace === 'eBay' && (
                                <CrossListEbay 
                                    formData={formData}
                                    setFormData={setFormData}
                                    templates={templates}
                                    ebayData={ebayData}
                                    setEbayData={setEbayData}
                                    categoryData={categoryData}
                                    categoryDataFetched={categoryDataFetched}
                                    setActiveMarketplace={setActiveMarketplace}
                                />
                            )}

                            {activeMarketplace === 'Poshmark' && (
                                <CrossListPoshmark
                                    formData={formData}
                                    setFormData={setFormData}
                                    ebayData={ebayData} //used for AIAssist to find category;
                                    poshmarkData={poshmarkData}
                                    setPoshmarkData={setPoshmarkData}
                                    setActiveMarketplace={setActiveMarketplace}
                                    submitMarketplaceExt={submitMarketplaceExt}
                                />  
                            )}

                            {activeMarketplace === 'Mercari' && (
                                <CrossListMercari 
                                    formData={formData}
                                    setFormData={setFormData}
                                    ebayData={ebayData} //used for AIAssist to find category;
                                    mercariData={mercariData}
                                    setMercariData={setMercariData}
                                    setActiveMarketplace={setActiveMarketplace}
                                    submitMarketplaceExt={submitMarketplaceExt}
                                />
                            )}
                        </div>

                    </div>
                </div>
            </main>
        </div>
    )
}

export default CrossListForm;
