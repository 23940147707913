import React, { useState, useEffect, useRef } from "react";
import { useUserAuth } from '../../context/UserAuthContext';
import { listingAPI } from '../../api';
import { BoxArrowUpRight } from 'react-bootstrap-icons';
import AITranslateCard from './AITranslateCard';

import { updateMarketplaceStatus } from '../../utils/updateStatus';


const MarketplaceFooter = ({
    formData, setFormData, marketplaceData, setMarketplaceData, savedMarketplaceDataRef, setMarketplaceChangeAfterSave, marketplaceName, 
    marketplaceChangeAfterSave, handleSubmitMarketplace, submitLoading, currentColor, alert, setAlert, setActiveMarketplace
}) => {
    const { user } = useUserAuth();
    const [marketplaceListingURL, setMarketplaceListingURL] = useState(null);
    const [draftLoading, setDraftLoading] = useState(false);

    useEffect(() => {
        if (marketplaceName === 'eBay' && marketplaceData.marketplaceListingId) {
            setMarketplaceListingURL(`https://www.ebay.com/itm/${marketplaceData.marketplaceListingId}`);
        }else if (marketplaceName === 'Poshmark' && marketplaceData.marketplaceListingId){
            setMarketplaceListingURL(`https://poshmark.com/listing/${marketplaceData.marketplaceListingId}`);
        }else if (marketplaceName === 'Mercari' && marketplaceData.marketplaceListingId){
            setMarketplaceListingURL(`https://www.mercari.com/us/item/${marketplaceData.marketplaceListingId}`);
        }

    }, [marketplaceData.marketplaceListingId]);

    const handleStartNew = () => {
        const newUrl = window.location.protocol + "//" + window.location.host + "/listing/new?mode=reload";
    
        if (window.location.href !== newUrl) {
            window.history.pushState({ path: newUrl }, '', newUrl);
        }

        setFormData({quantity: 1, utils: {mode: 'reloadPending'}});
    };


    const handleDraft = async () => {
        setDraftLoading(true);
        try {

            if (!validateDraft()) {
                setDraftLoading(false); 
                return; 
            }

            updateMarketplaceStatus(marketplaceName, 'submitting...', setFormData);

            let responseDraftData;
            const token = await user.getIdToken(true);

            const { pictures, ...restMarketplaceData } = marketplaceData;
            const updatedPictures = pictures.map(picture => {
                if (picture._id && picture._id.startsWith('temp')) {
                  return { imageUrl: picture.imageUrl };
                }
                return picture;
            });

            if(marketplaceData.id){
                responseDraftData = await listingAPI.updateMarketplaceListing(token, {...restMarketplaceData, pictures: updatedPictures }, formData, marketplaceData.id);
            }else{
                responseDraftData = await listingAPI.createMarketplaceListing(token, {...restMarketplaceData, pictures: updatedPictures, status: 'draft'}, formData );
            }
            console.log('responseDraftData - ', responseDraftData);

            updateMarketplaceStatus(marketplaceName, 'saved', setFormData, null, true);

            if (responseDraftData.marketplaceListingData) {
                let updates = {};
                if (responseDraftData.marketplaceListingData._id) { updates.id = responseDraftData.marketplaceListingData._id; }
                if (responseDraftData.marketplaceListingData.generalListing){ updates.generalListing = responseDraftData.marketplaceListingData.generalListing; }
                if (responseDraftData.marketplaceListingData.status){ updates.status = responseDraftData.marketplaceListingData.status; }
                if (Object.keys(updates).length > 0) {
                    setMarketplaceData(prevMercariData => ({
                        ...prevMercariData,
                        ...updates
                    }));
                }
                savedMarketplaceDataRef.current = {...marketplaceData, ...updates};
                setMarketplaceChangeAfterSave(false);
            }

            if(!formData.id && responseDraftData?.generalListingData?._id){
                setFormData(prevFormData => ({
                    ...prevFormData,
                    id: responseDraftData.generalListingData._id
                }));
            }

            setAlert({ message: '', type: '' }); //reset any previous alerts from errors

        } catch (err) {
            console.log('err - ', err);
            updateMarketplaceStatus(marketplaceName, 'error', setFormData);
            setAlert({message: `Error in saving draft: ${err.response?.data?.message}`, type: 'danger'});
        } finally {
            setDraftLoading(false); 
        }
    }

    const validateDraft = () => {
        //TODO: set up validation for draft? 
        return true;
    }

    const getNextMarketplace = (currentName, marketplaces) => {
        const currentIndex = marketplaces.findIndex(marketplace => marketplace.name === currentName);
        if (currentIndex === -1 || currentIndex + 1 >= marketplaces.length) {
            return false;
        }else{
            return marketplaces[currentIndex + 1];
        }
    }

    const marketplaceOrder = [
        {name: 'eBay', color: '#2937f0'}, {name: 'Poshmark', color: '#7f0353'}, {name: 'Mercari', color: '#5356ee'}
    ];

    const currentMarketplace = marketplaceOrder.find(marketplace => marketplace.name === marketplaceName);
    const nextMarketplace = getNextMarketplace(marketplaceName, marketplaceOrder);

    const handleNextClick = async (nextMarketplaceName) => {
        setActiveMarketplace(nextMarketplaceName);
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set('marketplace', nextMarketplaceName);
        window.history.pushState(null, '', '?' + searchParams.toString());
    }

    return (
        <>
            <div className="container">
                {alert.message && (
                    <div className={`alert alert-${alert.type}`} role="alert">
                        {alert.message}
                    </div>
                )}
            </div>

            <div className="text-end">
                {/* TODO: Option to Update eBay Listing if changes found */}
                {marketplaceData.marketplaceListingId && marketplaceListingURL ? (
                    <>
                        <button className="btn btn-success btn-sm" style={{marginRight: '5px'}} disabled>
                            Listed
                        </button>                            
                        <a 
                            href={marketplaceListingURL} 
                            target="_blank" 
                            rel="noopener noreferrer" 
                            style={{ backgroundColor: currentMarketplace.color, borderColor: currentMarketplace.color }}
                            className="btn btn-primary btn-sm"
                        >
                            <BoxArrowUpRight /> View on {marketplaceName}
                        </a>

                    </>
                ) : (
                    <>
                        {marketplaceData.id && !marketplaceChangeAfterSave ? (
                            <>
                                <button className="btn btn-success btn-sm" style={{marginRight: '5px'}} disabled>
                                    Saved
                                </button>
                            </>
                        ) : (
                            <button className="btn btn-secondary btn-sm" style={{marginRight: '5px'}} onClick={handleDraft}>
                                {draftLoading ? (
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                ): marketplaceChangeAfterSave ? (
                                    "Update Draft"
                                ): (
                                    "Save Draft"
                                )}
                            </button>
                        )}
                        

                        <button 
                            className="btn btn-primary btn-sm" 
                            onClick={handleSubmitMarketplace} 
                            style={{ backgroundColor: currentColor, borderColor: currentColor}}
                            disabled={submitLoading}
                        >
                            {submitLoading ? (
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            ) : (
                                `List on ${marketplaceName}`
                            )}
                        </button>
                    </>
                    
                )}

                {( marketplaceData.marketplaceListingId && marketplaceListingURL) || submitLoading ? (
                    nextMarketplace ? (
                        <button
                            className="btn btn-secondary btn-sm"
                            style={{ marginLeft: '5px', backgroundColor: nextMarketplace.color, borderColor: nextMarketplace.color }}
                            onClick={() => handleNextClick(nextMarketplace.name)}
                        >
                            Next: {nextMarketplace.name}
                        </button>
                    ) : (
                        <button
                            className="btn btn-secondary btn-sm"
                            style={{ marginLeft: '5px' }}
                            onClick={handleStartNew}
                        >
                            Start New Listing
                        </button>
                    )) : null
                }

            </div>
        </>
    )
}

const MarketplaceCard = ({
    formData, 
    setFormData,
    ebayData = {}, 
    marketplaceData, 
    setMarketplaceData, 
    marketplaceName, 
    handleSubmitMarketplace, 
    submitLoading, 
    alert, 
    setAlert,
    setActiveMarketplace,
    children
}) => {
    const [marketplaceChangeAfterSave, setMarketplaceChangeAfterSave]= useState(false);
    const savedMarketplaceDataRef = useRef(null);

    useEffect(() => { //if listing is loaded in, save current data ref to know when to 'Update' draft
        if(formData?.utils?.mode === 'loaded' && marketplaceData.id){
            savedMarketplaceDataRef.current = marketplaceData;
        }
    }, [formData?.utils?.mode, marketplaceData.id]);

    useEffect(() => { //update savedMarketplaceDataRef to change 'Saved' to 'Update' for drafts
        console.log('Saved status:', marketplaceData?.utils?.saved);

        if (marketplaceData?.utils?.saved) {
            savedMarketplaceDataRef.current = marketplaceData;
            setMarketplaceData(prevData => {
                const newUtils = { ...prevData.utils };
                delete newUtils.saved; // Remove saved property
                return {
                    ...prevData,
                    utils: newUtils
                };
            });
        }
    }, [marketplaceData?.utils?.saved]);
    

    useEffect(() => { //checks for changes made after draft is saved
        if(marketplaceData.id){
            if (savedMarketplaceDataRef.current && JSON.stringify({ ...marketplaceData, utils: {} }) !== JSON.stringify({ ...savedMarketplaceDataRef.current, utils: {} })) {
                setMarketplaceChangeAfterSave(true);
            }else{
                console.log('change not found savedMarketplaceDataRef - ', savedMarketplaceDataRef);
            }
        }
    }, [marketplaceData]);


    const marketplaceColor = {
        eBay: '#2937f0',
        Mercari: '#5356ee',
        Poshmark: '#7f0353',
        default: '#93E9BE'
    };
    const currentColor = marketplaceColor[marketplaceName] || marketplaceColor.default;

    return (
        <div className="card" id={marketplaceName} style={{marginBottom: '100px', border: `3px solid ${currentColor}`}}>
            <div 
                className="card-header"
                style={{ color: currentColor}}
            >
                {marketplaceName}
            </div>
            <div className="card-body">
                { marketplaceName !== 'eBay' && (
                    <AITranslateCard 
                        formData={formData}
                        ebayData={ebayData}
                        marketplaceData={marketplaceData}
                        setMarketplaceData={setMarketplaceData}
                        marketplaceName={marketplaceName}
                    />
                )}
                

                {children}
            </div>
            <div className="card-footer">
                <MarketplaceFooter 
                    formData={formData}
                    setFormData={setFormData}
                    marketplaceData={marketplaceData}
                    savedMarketplaceDataRef={savedMarketplaceDataRef}
                    setMarketplaceChangeAfterSave={setMarketplaceChangeAfterSave}
                    setMarketplaceData={setMarketplaceData}
                    marketplaceName={marketplaceName}
                    marketplaceChangeAfterSave={marketplaceChangeAfterSave}
                    handleSubmitMarketplace={handleSubmitMarketplace}
                    submitLoading={submitLoading}
                    currentColor={currentColor}
                    alert={alert}
                    setAlert={setAlert}
                    setActiveMarketplace={setActiveMarketplace}
                />
            </div>
        </div>
    )
}

export default MarketplaceCard;