import { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useUserAuth } from '../../context/UserAuthContext';
import { HouseFill, Gear, DoorClosed, Person } from 'react-bootstrap-icons';

import profileImage from '../../assets/img/profile-2.png';


const Navbar = () => {

  const { user, logOut } = useUserAuth();

  const navigate = useNavigate();

  const handleLogOut = async () => {
    try {
      await logOut();
      navigate('/login');
    }catch (err) {
      console.log(err.message)
    }
  }



  return (
    <nav className="topnav navbar navbar-expand shadow justify-content-between justify-content-sm-start navbar-light bg-white" id="sidenavAccordion">
      <Link to="/dashboard" className="navbar-brand pe-3 ps-4 ps-lg-2">
        <HouseFill style={{ fontSize: '1em', paddingBottom: '3px' }} /> AllButNew 
      </Link>
      {/* <form className="form-inline me-auto d-none d-lg-block me-3">
        <div className="input-group input-group-joined input-group-solid">
          <input className="form-control pe-0" type="search" placeholder="Search Listings" aria-label="Search" />
          <div className="input-group-text"> <Search /> </div>
        </div>
      </form> */}
      {/* Navbar Items */}
      <ul className="navbar-nav align-items-center ms-auto">
        {/* <ShortcutLinks /> */}
        {/* User Dropdown */}
        <li className="nav-item dropdown no-caret dropdown-user me-3 me-lg-4">
          <a className="btn btn-icon btn-transparent-dark dropdown-toggle" id="navbarDropdownUserImage" href="javascript:void(0);" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <Gear />
          </a>
          <div className="dropdown-menu dropdown-menu-end border-0 shadow animated--fade-in-up" aria-labelledby="navbarDropdownUserImage">
            <h6 className="dropdown-header d-flex align-items-center">
            <Person style={{ fontSize: '2.5em'}} />
            <div className="dropdown-user-details">
              <div className="dropdown-user-details-name">{user && user.displayName}</div>
              <div className="dropdown-user-details-email">{user && user.email}</div>
            </div>
            </h6>
            <div className="dropdown-divider"></div>
            <Link to="/settings/marketplaces" className="dropdown-item">
              <div className="dropdown-item-icon"> <Gear /> </div>
              Account Settings
            </Link>
            <a className="dropdown-item" href="#!" onClick={handleLogOut}>
              <div className="dropdown-item-icon"> <DoorClosed /> </div>
              Logout
            </a>
          </div>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
