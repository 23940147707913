import React, {useState, useEffect} from 'react';
import MarketplaceCard from './MarketplaceCard';
import PhotoPreview from './FormSimple/PhotoPreview';
import MarketplaceTitle from './FormSimple/MarketplaceTitle';
import MarketplaceDescription from './FormSimple/MarketplaceDescription';
import MarketplaceCategory from './MarketplaceCategory';
import MercariItemDetails from './MercariItemDetails';
import MercariPricingDetails from './MercariPricingDetails';

import { useUserAuth } from '../../context/UserAuthContext';
import { templateAPI } from '../../api';
import { updateMarketplaceStatus } from '../../utils/updateStatus';
import { validateMercari } from '../../utils/marketplace';

const CrossListMercari = ({formData, setFormData, ebayData, mercariData, setMercariData, setActiveMarketplace, submitMarketplaceExt}) => {
    const { user } = useUserAuth();
    const [submitLoading, setSubmitLoading] = useState(false);
    const [alert, setAlert] = useState({ message: '', type: '' });
    const [mercariCategories, setMercariCategories] = useState({});
    const [mercariSizeCharts, setMercariSizeCharts] = useState({});
    const [categorySizeCharts, setCategorySizeCharts] = useState({});

    const fetchMercariCategories = async () => {
        try {
            const token = await user.getIdToken(true);
            const data = await templateAPI.getMarketplaceCategories(token, 'Mercari')
            
            console.log('data - ', data);
            setMercariCategories(data.categories);
            setMercariSizeCharts(data.sizeCharts);


        } catch (error) {
            console.error("Error fetching category data:", error);
        }
    };

    useEffect(() => {
        if (Object.keys(mercariCategories).length === 0) {
            fetchMercariCategories();
        }
    }, []);

    useEffect(() => {
        if (mercariData?.category?.subCategory && Object.keys(mercariSizeCharts).length > 0) {
            console.log('Mercari SubCategory changed:', mercariData?.category?.subCategory);
            const categoryArray = mercariCategories?.[mercariData?.category?.department]?.[mercariData?.category?.category];
            const subCategoryObject = categoryArray?.find(item => item.name === mercariData?.category?.subCategory);
            const sizeChart = subCategoryObject?.sizeChart;

            console.log('categoryArray - ', categoryArray);
            console.log('subCategoryObject - ', subCategoryObject);
            console.log('sizeChart - ', sizeChart);

            if (!sizeChart) {
                console.log('NO SIZE CHART DELETING SIZE');
                setCategorySizeCharts({ sizeChartExists: false });
                setMercariData(prevData => {
                    const updatedData = { ...prevData };
                    delete updatedData.size;
                    return updatedData;
                });
            } else {
                console.log('ONLY Setting size Charts')
                setCategorySizeCharts({ sizeChartExists: true, sizeChartId: sizeChart, values: mercariSizeCharts?.[sizeChart] });
            }
        }
    }, [mercariData?.category?.subCategory, mercariSizeCharts]);

    useEffect(() => {
        if (mercariData?.category?.category && Object.keys(mercariSizeCharts).length > 0) {
            console.log('Mercari Category changed:', mercariData?.category);
    
            const categoryArray = mercariCategories?.[mercariData?.category?.department]?.[mercariData?.category?.category];
            if (categoryArray && categoryArray.length > 0) {
                const allHaveSameSizeChart = categoryArray.every(item => item.sizeChart === categoryArray[0].sizeChart);
                
                if (allHaveSameSizeChart) {
                    const sizeChart = categoryArray[0].sizeChart;
                    if (!sizeChart) {
                        console.log('ALL SIZE CHARTS ARE FALSE');
                        setCategorySizeCharts({ sizeChartExists: false });
                        setMercariData(prevData => {
                            const updatedData = { ...prevData };
                            delete updatedData.size;
                            return updatedData;
                        });
                    } else {
                        setCategorySizeCharts({ sizeChartExists: true, sizeChartId: sizeChart, values: mercariSizeCharts?.[sizeChart] });
                    }
                }
            }
        }
    }, [mercariData?.category?.category, mercariSizeCharts]); 

    const handleSubmitMercari = async () => {
        setSubmitLoading(true);
        setAlert({ message: '', type: '' }); 
        try {
            const validationResponse = validateMercari(mercariData, categorySizeCharts.sizeChartExists);
            if (!validationResponse.validated) {
                setAlert({ message: validationResponse.alertMessage, type: validationResponse.alertType });
                setSubmitLoading(false); 
                return; 
            }
            setAlert({ message: 'Mercari listing submitted in the background. Leave website open, but you can visit another page.', type: 'success' });
            updateMarketplaceStatus('Mercari', 'submitting...', setFormData);

            const extSubmitResponse = await submitMarketplaceExt(mercariData, "mercariListingWindow");
            console.log('extSubmitResponse - ', extSubmitResponse);
            if(extSubmitResponse.newListing){
                //request Ids do not match, New Listing button used; do nothing
                return;
            }

            if(extSubmitResponse.error){
                setAlert({message: extSubmitResponse.alertMessage, type: extSubmitResponse.alertType});
                return;
            }

            const responseData = extSubmitResponse.data;
            console.log('responseData - ', responseData);

            if (responseData?.marketplaceListingData) {

                let updates = {};
                if (responseData.marketplaceListingData._id) { updates.id = responseData.marketplaceListingData._id; }
                if (responseData.marketplaceListingData.generalListing){ updates.generalListing = responseData.marketplaceListingData.generalListing; }
                if (responseData.marketplaceListingData.status){ updates.status = responseData.marketplaceListingData.status; }
                if (responseData.marketplaceListingData.marketplaceListingId){ updates.marketplaceListingId = responseData.marketplaceListingData.marketplaceListingId; }

                if (Object.keys(updates).length > 0) {
                    console.log('updates - ', updates);

                    setMercariData(prevMercariData => ({
                        ...prevMercariData,
                        ...updates
                    }));
                }
            }

            //TODO: debug this step with async function does it take a stale value of formData?
            if(!formData.id && responseData?.generalListingData?._id){
                setFormData(prevFormData => ({
                    ...prevFormData,
                    id: responseData.generalListingData._id
                }));
            }

            setAlert({message: extSubmitResponse.alertMessage, type: extSubmitResponse.alertType});

        }catch (err) {
            updateMarketplaceStatus('Mercari', 'error', setFormData);
            setAlert({message: `Error in saving: ${err.response?.data?.message}`, type: 'danger'});
            return false;
        } finally {
            setSubmitLoading(false);
        }
    }

    return(
        <MarketplaceCard 
            formData={formData}
            setFormData={setFormData}
            ebayData={ebayData}
            marketplaceData={mercariData}
            setMarketplaceData={setMercariData}
            marketplaceName="Mercari"
            handleSubmitMarketplace={handleSubmitMercari}
            submitLoading={submitLoading}
            alert={alert}
            setAlert={setAlert}
            setActiveMarketplace={setActiveMarketplace}
        >
            <PhotoPreview
                formDataPictures={formData.pictures}
                marketplaceDataPictures={mercariData.pictures || []}
                setMarketplaceData={setMercariData}
                marketplaceName="Mercari"
                maxPhotos={12}
            />
            <MarketplaceTitle 
                marketplaceDataTitle={mercariData.title}
                setMarketplaceData={setMercariData}
                marketplaceName="Mercari"
            />
            <MarketplaceDescription
                marketplaceDataDescription={mercariData.description}
                setMarketplaceData={setMercariData}
                marketplaceName="Mercari"
            />
            <MarketplaceCategory 
                marketplaceDataCategory={mercariData.category}
                setMarketplaceData={setMercariData}
                marketplaceName="Mercari"
                marketplaceCategories={mercariCategories}
            />
            <MercariItemDetails
                mercariSize={mercariData.size}
                categorySizeCharts={categorySizeCharts}
                mercariCategory={mercariData.category}
                mercariCondition={mercariData.condition}
                mercariBrand={mercariData.brand}
                mercariColor={mercariData.color}
                setMarketplaceData={setMercariData}
            />
            <MercariPricingDetails
                mercariPrice={mercariData.price}
                mercariPackageDetails={mercariData.packageDetails || {}}
                mercariPricingDetails={mercariData.pricingDetails || {}}
                setMarketplaceData={setMercariData}
            />


        </MarketplaceCard>
    )
}

export default CrossListMercari;