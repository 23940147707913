import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Footer from '../Helper/Footer';
import { useUserAuth } from '../../context/UserAuthContext';
import { userAPI, settingAPI } from '../../api';
import OnboardImport from './OnboardImport';
import { PlusSquare, StarFill } from 'react-bootstrap-icons';


const OnboardEbayConnection = ({ setMessage, setAlertType }) => {
    const { user } = useUserAuth();
    const [ebayLinkLoading, setEbayLinkLoading] = useState(false);

    const handleEbayConnect = async () => {
        if (!user) {
            throw new Error("User account not found");
        }
        try {
            setEbayLinkLoading(true);
            const token = await user.getIdToken(true);
            const response = await userAPI.getEbayAuthorizationLink(token);
            const authUrl = response.url;
            window.location.href = authUrl;
        } catch (error) {
            console.error('Error connecting to eBay:', error);
            setMessage(`Error connecting to eBay: ${error.message || error}`);
            setAlertType('danger');
            setEbayLinkLoading(false); 
        }
    };

    return (
        <>
            <h3 className="text-primary">Step 1</h3>
            <h5 className="card-title mb-4">Connect Your eBay Account</h5>

            <button onClick={handleEbayConnect} className="btn btn-primary mb-4" disabled={ebayLinkLoading}>
                {ebayLinkLoading ? (
                    <>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Redirecting
                    </>
                ) : (
                    "Connect to eBay"
                )}
            </button>

            <div className="info-section">
                <p>
                    We connect directly to your eBay account via the eBay API, the official way for third-party access.
                    We never have your password. This connection allows us to perform certain actions within our software.
                    A few of our uses with the eBay API,
                </p>
                <ul>
                    <li>Import Active Listings</li>
                    <li>Import Sold Listings</li>
                    <li>Create New Listings</li>
                    <li>Access Business Policies</li>
                    <li>New Features Coming as We Grow!</li>
                </ul>
            </div>

        </>
    )
}

const OnboardBusinessPolicies = ({ setMessage, setAlertType, activeStep, setActiveStep }) => {
    const { user } = useUserAuth();
    const [businessPoliciesLoading, setBusinessPoliciesLoading] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [setupRequired, setSetupRequired] = useState(false);
    const [policiesFound, setPoliciesFound] = useState(true);
    const [storeData, setStoreData] = useState({});
    const [policyDefault, setPolicyDefault] = useState({});

    const handleRefresh = async () => {
        await fetchStorePolicies();
    }

    const fetchStorePolicies = async () => {
        try {
            setBusinessPoliciesLoading(true);
            const token = await user.getIdToken(true);
            const storeDataResponse = await userAPI.getStoreData(token, true);
            setBusinessPoliciesLoading(false);
            console.log('storeDataResponse - ', storeDataResponse);

            if(!storeDataResponse.businessPolicies){
                setSetupRequired(true);
                return;
            }else{
                setStoreData(storeDataResponse); 
                if(!storeDataResponse.fulfillmentPolicies.length > 0 || !storeDataResponse.paymentPolicies.lenght > 0 || !storeDataResponse.returnPolicies.length > 0){
                    console.log('IF CHECK')
                    setPoliciesFound(false);
                    setSetupRequired(true);
                }

                if(!storeDataResponse.fulfillmentPolicies.length > 0){
                    console.log('First IF 1', storeDataResponse.fulfillmentPolicies.length)
                    setMessage('No Shipping Policies found. Please add & refresh.')
                    setAlertType('warning');    
                    return;
                }else if(!storeDataResponse.paymentPolicies.length > 0){
                    setMessage('No Payment Policies found. Please add & refresh.')
                    setAlertType('warning');    
                    return;
                }else if(!storeDataResponse.returnPolicies.length > 0){
                    setMessage('No Return Policies found. Please add & refresh.')
                    setAlertType('warning');    
                    return;
                }
            }

            setSetupRequired(false);
            setPoliciesFound(true);
            return storeDataResponse;
        } catch (error) {
            setBusinessPoliciesLoading(false);
            console.error("Error fetching Business Policies:", error);
            setMessage(`Error Business Policies: ${error.message || error}`);
            setAlertType('danger');    
        }
    }

    useEffect(() => {
        const fetchAndProcessStorePolicies = async () => {
            await fetchStorePolicies();
        };

        if (activeStep === 2 && user) {
            console.log('active step 2 & user')
            fetchAndProcessStorePolicies();
        }
    }, [activeStep, user]);
    

    const handleChange = (e, path) => {
        setPolicyDefault(prevState => ({
            ...prevState,
            [path]: e.target.value
        }));
    };

    useEffect(() => {
        console.log(policyDefault);
    }, [policyDefault]);

    const getPolicyById = (id, policiesArray) => policiesArray.find(policy => policy.id === id);

    const handleFormSubmit = async (e) => {
        setSubmitLoading(true);
        e.preventDefault(); 

        if(!policyDefault.fulfillmentPolicy || !policyDefault.paymentPolicy || !policyDefault.returnPolicy){
            setMessage('Please select default policies for Payment, Return, & Fulfillment. Submit again when completed.')
            setAlertType('warning');
            setSubmitLoading(false);
            return;
        }

        try {
            const token = await user.getIdToken(true); 
            const listingDefaultsResponse = await settingAPI.createDefaults(token, {
                ebay: {
                    sellerProfiles: {
                        fulfillmentPolicy: getPolicyById(policyDefault.fulfillmentPolicy, storeData.fulfillmentPolicies),
                        paymentPolicy: getPolicyById(policyDefault.paymentPolicy, storeData.paymentPolicies),
                        returnPolicy: getPolicyById(policyDefault.returnPolicy, storeData.returnPolicies)    
                    }
                }
            })
            setMessage(listingDefaultsResponse.message);
            setAlertType(listingDefaultsResponse.alertType);
            setSubmitLoading(false);
            setActiveStep(3);
            
        } catch (error) {
            console.error('Error toggling auto import:', error);
            setMessage(error);
            setAlertType('danger');
            setSubmitLoading(false);
        }

    }

    const PolicyDropdown = ({ label, policies, policyType, handleChange, policyDefault }) => (
        <div className="row mb-3 align-items-center">
            <div className="col-md-4">
                <label htmlFor={policyType} className="form-label"><p>{label}</p></label>
            </div>
            <div className="col-md-8">
                <select 
                    className="form-select" 
                    id={policyType}
                    value={policyDefault[policyType] || ''}
                    onChange={(e) => handleChange(e, policyType)}
                >
                    <option value="">Select {label}</option>
                    {policies && policies.length > 0 ? (
                        policies.map(policy => (
                            <option key={policy.id} value={policy.id}>{policy.name}</option>
                        ))
                    ) : null}
                </select>
            </div>
        </div>
    );
    

    return(
        <>
            <h3 className="text-primary">Step 2</h3>
            <h5 className="card-title mb-4">Business Policies</h5>

                {businessPoliciesLoading ? (
                    <>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading Store Business Policies
                    </>
                ) : (<></>) }

                {setupRequired ? (
                    <div className="info-section">
                        <p>
                            { !policiesFound ? 
                                (<>We see you have enabled Business Policies on your eBay Account. Please ensure you have at least 1 policy for Return, Payment, and Shipping. 
                                Create your policies within your <a href="https://www.bizpolicy.ebay.com/businesspolicy/manage" target="_blank" rel="noopener noreferrer">eBay Settings</a>.
                                When finished, return to this page and click the Refresh button below.</>) 
                                : (<>To use ThriftAllDay, you must enable Business Policies on your eBay Account. Business Policies allow you to set templates
                                for payment, shipping, and return details for buyers. These templates will be applied when creating new listings on ThriftAllDay.
                                Setup your Business Policies on eBay and then click the Refresh button below.</>)
                            }
                        </p>
                        <div className="row">
                            <a href="https://www.bizpolicy.ebay.com/businesspolicy/policyoptin" target="_blank" rel="noopener noreferrer" className="btn btn-primary mt-2">
                                    Opt-In to Business Policies on eBay
                            </a>
                        </div>
                        <button className="btn btn-secondary mt-2" onClick={handleRefresh}>
                            Refresh
                        </button>
    
                    </div>
                ) : (<></>) }

                {!setupRequired && !businessPoliciesLoading ? (
                    <>
                    <div className="info-section">
                        <p>
                            We've loaded the Business Policies from your eBay account. Choose defaults to use when creating a listing. Pick your most common Business Policy for Return, Payment, & Shipping. 
                            When creating a listing, you can always change to any policy you have. To view full details of a policy, go to your <a href="https://www.bizpolicy.ebay.com/businesspolicy/manage" target="_blank" rel="noopener noreferrer">eBay Settings</a>. 
                            Your options can be adjusted anytime in Account Settings. 
                        </p>
                        <p>
                            <button className="btn btn-secondary mt-2" onClick={handleRefresh}>
                                Refresh
                            </button>
                        </p>
                    </div>

                   

                    <form onSubmit={handleFormSubmit}>

                        <PolicyDropdown 
                            label="Payment Policy" 
                            policies={storeData.paymentPolicies} 
                            policyType="paymentPolicy" 
                            handleChange={handleChange}
                            policyDefault={policyDefault}
                        />

                        <PolicyDropdown 
                            label="Return Policy" 
                            policies={storeData.returnPolicies} 
                            policyType="returnPolicy" 
                            handleChange={handleChange}
                            policyDefault={policyDefault}
                        />

                        <PolicyDropdown 
                            label="Shipping (Fulfillment) Policy" 
                            policies={storeData.fulfillmentPolicies} 
                            policyType="fulfillmentPolicy" 
                            handleChange={handleChange}
                            policyDefault={policyDefault}
                        />

                        <div className="d-flex justify-content-end mt-3">
                            <button type="submit" className="btn btn-primary" disabled={submitLoading}>
                                { submitLoading ? (
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                 ) : ( "Submit Defaults" ) }
                                </button>
                        </div>

                    </form>
                    </>
                ): (<></>)}
        </>
    )
}

const OnboardExtension = () => {
    const navigate = useNavigate();

    const handleSkipClick = async () => {
        console.log('handle skip click');
        navigate('/');
    }

    return (
        <>
            <h3 className="text-primary">Chrome Extension</h3>
            <h5 className="card-title mb-4">Connect to use Poshmark & Mercari Marketplaces</h5>
    
            <div className="info-section">
                <p>
                    This extension helps you seamlessly integrate your activities on Poshmark and Mercari, enhancing your reselling efforts with streamlined functionality.
                </p>
                <a href="https://chrome.google.com/webstore/detail/allbutnew-reseller-tools/chfnddcnoglhnhpebloegfkomhdifohn" target="_blank" rel="noopener noreferrer">
                    <button className="btn btn-primary">Get the Extension</button>
                </a>

                <div className="d-flex justify-content-end mt-3">
                    <button className="btn btn-secondary" onClick={handleSkipClick}>Next</button>
                </div>
            </div>
        </>
    )
}

const OnboardMain = () => {
    const [activeStep, setActiveStep] = useState(1);
    const [message, setMessage] = useState('');
    const [alertType, setAlertType] = useState('info');
    const { user } = useUserAuth();
    const navigate = useNavigate(); 
    const location = useLocation();

    useEffect(() => {
        if (location.state && location.state.message && location.state.ebayUsername) {
            setActiveStep(2);
            setMessage(`${location.state.message} Account ${location.state.ebayUsername} connected`);
            setAlertType('success');
        }
    }, [location.state]);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const token = await user.getIdToken(true);
                const userData = await userAPI.getUser(token);
                if (!userData.betaAccess) {
                    navigate('/onboard/waitlist');
                }
                if(userData.storeSetup){
                    navigate('/dashboard');
                }
                if(userData.ebayStore){
                    setActiveStep(2);
                }

            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };
        if(user){
            console.log('USER found.')
            fetchUserData();
        }else{
            console.log('NO USER!');
            //send to home
        }
    }, [user]);

    const steps = [
        { 
            id: "wizard1", 
            component: <OnboardEbayConnection setMessage={setMessage} setAlertType={setAlertType} />,
            step: 1,
            name: "eBay Connection",
            details: "Connect To Your Account"
        },
        { 
            id: "wizard2", 
            component: <OnboardBusinessPolicies setMessage={setMessage} setAlertType={setAlertType} activeStep={activeStep} setActiveStep={setActiveStep} />,
            step: 2,
            name: "Business Policies",
            details: "eBay Account Information"
        }, 
        { 
            id: "wizard3", 
            component: <OnboardExtension />,
            step: 3,
            name: "Chrome Extension",
            details: "Connect to Marketplaces"
        }
    ];

    return (
        <div>
        <div>

        <main>
            <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                <div className="container-xl px-4">
                    <div className="page-header-content pt-4">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-auto mt-4">
                                <h1 className="page-header-title">
                                    Onboarding
                                </h1>
                                <div className="page-header-subtitle">Let's Set Up Your Reselling Hub!</div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <div className="container-xl px-4 mt-n10">
                <div className="card">
                    <div className="card-header border-bottom">
                    <div className="nav nav-pills nav-justified flex-column flex-xl-row nav-wizard" id="cardTab" role="tablist">
                    {steps.map((tab, index) => (
                        <a
                            key={tab.id}
                            className={`nav-item nav-link ${tab.step === activeStep ? "active" : "disabled"}`}
                            id={`${tab.id}-tab`}
                            href={`#${tab.id}`}
                            data-bs-toggle="tab"
                            role="tab"
                            aria-controls={tab.id}
                            aria-selected={tab.step === activeStep ? "true" : "false"}
                        >
                        <div className="wizard-step-icon">{tab.step}</div>
                        <div className="wizard-step-text">
                            <div className="wizard-step-text-name">{tab.name}</div>
                            <div className="wizard-step-text-details">{tab.details}</div>
                        </div>
                        </a>
                    ))}
                    </div>
                    </div>
                    <div class="card-body">
                        <div class="tab-content" id="cardTabContent">
                        {steps.map((step) => (
                            <div 
                                key={step.id}
                                className={`tab-pane py-5 py-xl-10 fade ${step.step === activeStep ? "active show" : ""}`} 
                                id={step.id} 
                                role="tabpanel" 
                                aria-labelledby={`${step.id}-tab`} 
                                style={{ paddingTop: "25px" }}
                            >
                                    <div className="row justify-content-center">
                                        <div className="col-xxl-6 col-xl-8">
                                            {message && (
                                                <div className={`alert alert-${alertType}`} role="alert">
                                                    {message}
                                                </div>
                                            )}

                                            {step.component}
                                        </div>
                                    </div>
                            </div>
                        ))}
                        </div>
                    </div>

                </div>
            </div>
        </main>
        </div>

        <Footer />
    </div>
    );
}

export default OnboardMain;
