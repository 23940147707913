import React, { useState, useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import styles

const DescriptionDefault = ({settingsData, setSettingsData}) => {
    const [editorContent, setEditorContent] = useState('');
    const [quillRef, setQuillRef] = useState(null);
    const initialLoad = useRef(true);

    useEffect(() => {
        if (initialLoad.current && settingsData.description) {
            setEditorContent(settingsData.description);
            initialLoad.current = false; 
        }
    }, [settingsData.description]);
    
    const handleEditorChange = (content, delta, source, editor) => {
        setSettingsData(prevData => ({
            ...prevData, 
            description: content
        }));
        setEditorContent(content);
    };

    const insertVariable = (variable) => {
        const range = quillRef.getEditor().getSelection();
        if (range) {
            quillRef.getEditor().insertText(range.index, variable);
        }
    };

    return (
        <>
            <p>Use variables, we will dynamically convert the variables as you create your listing. Variables available for [title], [conditionDescription], and [size].</p>

                <div className="variable-buttons">
                    <button type="button" className="btn btn-secondary me-2" onClick={() => insertVariable('[title]')}>Title</button>
                    <button type="button" className="btn btn-secondary me-2" onClick={() => insertVariable('[conditionDescription]')}>Condition Description</button>
                    <button type="button" className="btn btn-secondary me-2" onClick={() => insertVariable('[size]')}>Size</button>
                </div>
                <div className="mb-3"></div>
            <div>
                <ReactQuill
                    ref={(el) => {
                        if (el) setQuillRef(el);
                    }}
                    value={editorContent}
                    onChange={handleEditorChange}
                    theme="snow"
                >
                </ReactQuill>
            </div>
        </>
    );
}

export default DescriptionDefault;
