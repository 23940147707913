// updateStatus.js

/**
 * Asynchronously updates or adds the status of a marketplace in the form data state. Optionally includes a listing ID,
 * always adds a timestamp for the update, and optionally updates the general status to 'saved'.
 * @param {string} marketplaceName - The name of the marketplace to update.
 * @param {string} status - The new status to set for the marketplace.
 * @param {function} setFormData - The React state setter function for form data.
 * @param {string|null} marketplaceListing - The listing ID of the marketplace, if applicable.
 * @param {boolean} updateGeneral - Whether to update or add a general marketplace with status 'saved'.
 * @returns {Promise<Object>} The updated form data.
 */
const updateMarketplaceStatus = async (marketplaceName, status, setFormData, marketplaceListing = null, updateGeneral = false) => {
    console.log('marketplaceListing - ', marketplaceListing);

    return new Promise((resolve, reject) => {
        setFormData(prevData => {
            console.log('updateMarketplaceStatus prevData - ', prevData);

            const newFormData = createMarketplaceUpdate(prevData, marketplaceName, status, marketplaceListing, updateGeneral)

            resolve(newFormData);
            return newFormData;
        });
    });
};

function createMarketplaceUpdate(formData, marketplaceName, status, marketplaceListing, updateGeneral){
    const updatedMarketplaces = formData.marketplaces ? [...formData.marketplaces] : [];
    const index = updatedMarketplaces.findIndex(marketplace => marketplace.name === marketplaceName);

    // Update or add the specific marketplace entry
    if (index !== -1) {
        updatedMarketplaces[index] = {
            ...updatedMarketplaces[index],
            status: status,
            dateUpdated: new Date().toISOString(),
            ...(marketplaceListing && { marketplaceListing })
        };
    } else {
        updatedMarketplaces.push({
            name: marketplaceName,
            status: status,
            dateUpdated: new Date().toISOString(),
            ...(marketplaceListing && { marketplaceListing })
        });
    }

    // Optionally update or add 'General' marketplace entry
    if (updateGeneral) {
        const generalIndex = updatedMarketplaces.findIndex(m => m.name === 'General');
        if (generalIndex !== -1) {
            updatedMarketplaces[generalIndex] = {
                ...updatedMarketplaces[generalIndex],
                status: 'saved',
                dateUpdated: new Date().toISOString()
            };
        } else {
            updatedMarketplaces.push({
                name: 'General',
                status: 'saved',
                dateUpdated: new Date().toISOString()
            });
        }
    }

    // Construct new form data to resolve
    const newFormData = { ...formData, marketplaces: updatedMarketplaces };
    return newFormData;
}

// Export the function for use in other files
export { updateMarketplaceStatus, createMarketplaceUpdate };