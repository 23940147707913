import React, { useState, useEffect } from "react";



const ImagePreviewModal = ({ show, onClose, imageUrl }) => {
    // Copy from ImageSimple
    return (
        <>
            {show && <div className="modal-backdrop show"></div>}
            <div className={`modal ${show ? 'show d-block' : ''}`} tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Image Preview</h5>
                            <button type="button" className="btn-close" onClick={onClose}></button>
                        </div>
                        <div className="modal-body">
                            <img src={imageUrl} alt="Preview" className="img-fluid" />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={onClose}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const MarketplaceSideNav = ({ formDataMarketplaces, activeMarketplace, setActiveMarketplace, imageData, extInstalled }) => {
    const [showModal, setShowModal] = useState(false);
    const [currentImageUrl, setCurrentImageUrl] = useState('');
    const marketplaceOptionsDefault = [
        { label: "General", borderColor: '#93E9BE', textColor: 'black', status: 'Not Saved', statusStyle: 'light', usesExtension: false }, 
        { label: "eBay", borderColor: '#2937f0', textColor: 'white', status: 'Not Listed', statusStyle: 'light', usesExtension: false },
        { label: "Poshmark", borderColor: '#7f0353', textColor: 'white', status: 'Not Listed', statusStyle: 'light', usesExtension: true },
        { label: "Mercari", borderColor: '#5356ee', textColor: 'white', status: 'Not Listed', statusStyle: 'light', usesExtension: true }
    ];
    const [marketplaceOptions, setMarketplaceOptions] = useState(marketplaceOptionsDefault);

    function capitalizeWords(str) {
        return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
    }

    useEffect(() => {
        const styleConvert = {
            'listed': 'primary',
            'active': 'primary',
            'saved': 'primary',
            'draft': 'light',
            'sold': 'success',
            'delisted': 'warning',
            'submitting...': 'light',
            'error': 'danger'
        }

        if(formDataMarketplaces.length > 0 ){
            console.log('formDataMarketplaces - ', formDataMarketplaces);

            const updatedMarketplaceOptions = marketplaceOptions.map(option => {
                const marketplaceUpdate = formDataMarketplaces.find(marketplace => marketplace.name === option.label);
                if (marketplaceUpdate) {
                    return {
                        ...option,
                        status: capitalizeWords(marketplaceUpdate.status),
                        statusStyle: styleConvert[marketplaceUpdate.status] || option.statusStyle
                    };
                }
                return option;
            });

            setMarketplaceOptions(updatedMarketplaceOptions);
        }else if(formDataMarketplaces.length === 0 && marketplaceOptions !== marketplaceOptionsDefault){
            //Reset Case
            setMarketplaceOptions(marketplaceOptionsDefault);
        }
    }, [formDataMarketplaces]);
    

    const handleMarketplaceClick = (label) => {
        setActiveMarketplace(label);
        
        // If label === 'General', then remove the marketplace query param
        const searchParams = new URLSearchParams(window.location.search);
        if (label === 'General') {
            searchParams.delete('marketplace');
            const baseUrl = window.location.pathname;
            window.history.pushState(null, '', baseUrl);
        } else {
            searchParams.set('marketplace', label.toLowerCase());
            window.history.pushState(null, '', '?' + searchParams.toString());
        }
    };
    

    const handleImageClick = (imageUrl) => {
        setCurrentImageUrl(imageUrl);
        setShowModal(true);
    };

    const handleCloseModal = () => setShowModal(false);

    return (
    <>
        <div className="nav-sticky">
            <div className="card">
                {/* <div className="card-header">Marketplaces</div> */}
                <div className="card-body" style={{ padding: 0 }}>
                    <div className="d-flex flex-column align-items-start" id="stickyNav">

                        {/* Options Display */}
                        {marketplaceOptions.map((option, index) => {
                            const isActive = extInstalled || !option.usesExtension;
                            return (
                                <div
                                    key={index}
                                    className={`d-flex align-items-center p-3 w-100 ${!isActive ? 'bg-light text-muted' : ''}`}
                                    style={{
                                        cursor: isActive ? 'pointer' : 'not-allowed',
                                        color: isActive ? (activeMarketplace === option.label ? option.textColor : 'black') : 'grey',
                                        margin: 0,
                                        borderLeft: `5px solid ${option.borderColor}`,
                                        backgroundColor: isActive ? (activeMarketplace === option.label ? option.borderColor : 'transparent') : '#f8f9fa',
                                        justifyContent: 'space-between',
                                    }}
                                    onClick={() => isActive && handleMarketplaceClick(option.label)}
                                >
                                    <span style={{ flex: 1 }}>{option.label}</span>
                                    {!isActive ? (
                                        <a href="https://chromewebstore.google.com/detail/allbutnew-reseller-tools/chfnddcnoglhnhpebloegfkomhdifohn" 
                                        className={`badge text-bg-secondary`} 
                                        target="_blank" 
                                        rel="noopener noreferrer" 
                                        style={{ textDecoration: 'none' }}>
                                            Install Extension
                                        </a>
                                    ) : (
                                        <span className={`badge text-bg-${option.statusStyle}`}>
                                            {option.status}
                                        </span>
                                    )}
                                </div>
                            );
                        })}

                        {/* Display images with scroll */}
                        <div style={{ maxHeight: '75vh', overflowY: 'auto' }}>
                            {imageData && imageData.map((data, index) => (
                                <div key={index} className="mb-2 p-2 w-100" onClick={() => handleImageClick(data.firebaseURL || data.blobURL)}>
                                    <img src={data.firebaseURL || data.blobURL} alt={`Image ${index + 1}`} className="img-fluid" />
                                </div>
                            ))}
                        </div>


                    </div>
                </div>
            </div>
        </div>
        {/* Image Preview Modal */}
        <ImagePreviewModal
            show={showModal}
            onClose={handleCloseModal}
            imageUrl={currentImageUrl}
        />
    </>
    )
};

export default MarketplaceSideNav;