import { createContext, useEffect, useState, useContext } from "react";
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signOut,
    onAuthStateChanged,
    updateProfile,
    sendPasswordResetEmail,
    getIdToken,
} from "firebase/auth";
import { auth } from "../firebase";
import { userAPI } from '../api'

const userAuthContext = createContext();

export function UserAuthContextProvider({ children }){
    const [user, setUser] = useState(null);
    const [token, setToken] = useState("");

    async function signUp(email, password, displayName, inviteCode) {
        console.log('user auth signup')
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            console.log('userCredential - ', userCredential);
            await updateProfile(userCredential.user, {displayName: displayName});
            const token = await getIdToken(userCredential.user);

            const userData = {
                firebaseuid: userCredential.user.uid,
                email: email,
                displayName: displayName,
                inviteCode: inviteCode ? inviteCode : 'NONE'
            };

            const backendUser = await userAPI.createUser(token, userData);
            console.log('backendUser - ', backendUser);

          return { userCredential, backendUser };
        } catch (error) {
          console.error(error);
          throw error;
        }
    }

    function logIn(email, password){
        return signInWithEmailAndPassword(auth, email, password);
    }

    function logOut(){
        return signOut(auth);
    }

    function forgotPassword(email) {
        const actionCodeSettings = {
            url: 'https://thriftallday-37c6b.web.app/login'
        };
        return sendPasswordResetEmail(auth, email, actionCodeSettings);
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
            setUser(currentUser);
            if (currentUser) {
                const token = await getIdToken(currentUser); // Get the token for the logged in user
                setToken(token); // Update the token state
            } else {
                // Handle user sign-out or no user state
                setToken(""); // Clear the token if there's no user
            }
        });

        return () => {
            unsubscribe();
        }
    }, []);

    return (
        <userAuthContext.Provider value={{user, token, signUp, logIn, logOut, forgotPassword}}>
            {children}
        </userAuthContext.Provider>
    )
}


export function useUserAuth(){
    return useContext(userAuthContext)
}