import React, { useState, useEffect } from 'react';
import { useExtension } from '../../../context/ExtensionContext';
import { userAPI } from '../../../api';
import { useUserAuth } from '../../../context/UserAuthContext';
const EXTENSION_ID = 'lihacffkhilnankbajidkpnpmljagfii';

const ExtensionSection = ({name, color, extSection}) => {
    const { extInstalled, sendMessageToExtension } = useExtension();
    const checkInstallation = () => {
        const requestId = `check_install_${Date.now()}`;
        sendMessageToExtension({ action: 'checkInstallation', data: null, formData: null, token: null, requestId })
    };

    useEffect(() => {
        checkInstallation();
    }, []);

    return (
        <MarketplaceSection
            name={name}
            color={color}
            extSection={extSection}
            extInstalled={extInstalled}
        />
    )
    
}

const MarketplaceSection = ({ name, color, connectionData = {}, loading = false, extSection = false, extInstalled = false }) => {
    const marketplaceData = connectionData?.[name.toLowerCase()] ?? {};
    const [loadingMarketplace, setLoadingMarketplace] = useState(false);
    const { user } = useUserAuth();

    const handleConnectEbay = async () => {
        try {
            setLoadingMarketplace(true);
            const token = await user.getIdToken(true);
            const response = await userAPI.getEbayAuthorizationLink(token);
            console.log('handleConnectEbay response - ', response);
            const authUrl = response.url;
            window.location.href = authUrl;
        } catch (error) {
            console.error('Error connecting to eBay:', error);
        } finally {
            setLoadingMarketplace(false);
        }
    };

    let content;
    if(extSection && extInstalled){
        content = <p>Extension Installed</p>;
    }else if(extSection && !extInstalled){
        content = <a href="https://chrome.google.com/webstore/detail/allbutnew-reseller-tools/chfnddcnoglhnhpebloegfkomhdifohn" className="text-white" target="_blank" rel="noopener noreferrer">Install</a>;
    }else if (marketplaceData.comingSoon) {
        content = <p>Connects with Extension</p>;
    } else if (name === 'eBay' && !marketplaceData.username) {
        content = (
            <div>
                <button className="btn btn-light" onClick={handleConnectEbay} disabled={loadingMarketplace}>
                    {loadingMarketplace ? (
                        <>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Redirecting...
                        </>
                    ) : (
                        "Connect eBay"
                    )}
                </button>
            </div>
        );
    } else {
        content = <p>{marketplaceData.username}</p>;
    }
    

    return (
        <div className="card-body" style={{ backgroundColor: color, color: 'white' }}>
            <h5 style={{ margin: 0, color: 'white' }}>{name}</h5>
            <div style={{ textAlign: 'right' }}>
                {loading ? <p>Loading...</p> : content }
            </div>
        </div>
    );
};

const MarketplaceConnections = ({ connectionData, loading }) => {

    console.log('connectionData - ', connectionData);
    console.log('loading - ', loading);

    return (
        <div className="card mb-4 mb-xl-0">
            <div className="card-header">Marketplace Connections</div>
                {/* Extension Section */}
                <ExtensionSection 
                    name="AllButNew"
                    color="#93e9be"
                    extSection="true"
                />
                {/* eBay Section */}
                <MarketplaceSection
                    name="eBay"
                    color="#2937f0"
                    connectionData={connectionData}
                    loading={loading}
                />
                {/* Mercari Section */}
                <MarketplaceSection
                    name="Mercari"
                    color="#5356ee"
                    content="Coming Soon"
                    connectionData={connectionData}
                    loading={loading}
                />
                {/* Poshmark Section */}
                <MarketplaceSection
                    name="Poshmark"
                    color="#7f0353"
                    content="Coming Soon"
                    connectionData={connectionData}
                    loading={loading}
                />
        </div>
    );
}

export default React.memo(MarketplaceConnections);;
