import React from 'react';

const AuthFooter = () => {
    const currentYear = new Date().getFullYear();

    return(
        <div id="layoutAuthentication_footer">
            <footer className="footer-admin mt-auto footer-dark">
                <div className="container-xl px-4">
                    <div className="row">
                        <div className="col-md-6 small">Copyright © ThriftAllDay {currentYear}</div>
                        <div className="col-md-6 text-md-end small">
                            <a href="https://allbutnew.com/privacy-policy">Privacy Policy</a>
                            &middot;
                            <a href="https://allbutnew.com/terms-of-service">Terms & Conditions</a>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}




export default AuthFooter;