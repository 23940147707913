import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import { saveDataToDatabase } from '../utils/saveDatabase';

// Create the context
const ExtensionContext = createContext();

// Provider component
export const ExtensionProvider = ({ children }) => {
    const [extInstalled, setExtInstalled] = useState(false);
    const [requests, setRequests] = useState({});  // Keeps track of ongoing requests
    const checkExtensionTimeoutRef = useRef(null); // Ref to store the timeout

    // Function to send messages to the extension with requestId
    const sendMessageToExtension = ({ action, data, formData, token, requestId }) => {
        return new Promise((resolve, reject) => {
            console.log(`Sending message: ${action} with requestId: ${requestId}`);
            window.postMessage({ action, data, fromWebpage: true, requestId }, "*");

            setRequests(prev => ({
                ...prev,
                [requestId]: { action, data, formData, token, status: 'pending', resolve, reject }
            }));
        });
    };

    useEffect(() => {
        const handleExtensionResponse = (event) => {
            console.log('handleExtensionResponse event - ', event.data);

            if (event.source === window && event.data && event.data.fromExtension) {
                const { requestId, action, data, error } = event.data;

                if (action === 'checkInstallation') {
                    console.log('ACTION checkInstallion TRUE')
                    setExtInstalled(true);
                    clearTimeout(checkExtensionTimeoutRef.current); // Clear the timeout if extension is installed
                    return;
                }

                console.log('TOTAL EVENT: ', event.data);
                console.log('requests[requestId] 0 - ', requests[requestId]);
                console.log('requestId - ', requestId);
                if(event.data?.data?.error){
                    if(event.data?.data?.error === 'LOGGED_OUT'){
                        requests[requestId].resolve({ action, data: { error: 'LOGGED_OUT'} , requestId });
                    }

                    //TODO: delete request?
                    return;
                }

                if (requests[requestId]) {
                    const marketplaceListingId = data?.listingId;
                    const marketplaceData = requests[requestId].data;
                    const formData = requests[requestId].formData;
                    const token = requests[requestId].token;

                    console.log('marketplaceListingId - ', marketplaceListingId);

                    saveDataToDatabase(token, marketplaceData, formData, marketplaceListingId, 'active').then((responseData) => {
                        console.log('Data saved to database successfully responseData - ', responseData);
                        requests[requestId].resolve({ action, data: { listingId: marketplaceListingId }, responseData, requestId });
                    }).catch(console.error);

                    setRequests(prev => {
                        const newRequests = { ...prev };
                        delete newRequests[requestId];  // Clean up the request
                        return newRequests;
                    });
                }
            }
        };

        window.addEventListener("message", handleExtensionResponse);

        return () => {
            window.removeEventListener("message", handleExtensionResponse);
        };
    }, [requests]);

    useEffect(() => {
        // Check if the extension is installed
        const checkExtensionInstalled = () => {
            const requestId = `check_install_${Date.now()}`;
            console.log('checking chrome ext is installed - ', requestId);

            sendMessageToExtension({ action: 'checkInstallation', data: null, formData: null, token: null, requestId })
                .catch(() => {
                    console.log('SEND MESSAGE CATCH');
                    setExtInstalled(false);
                });

            // Set a timeout to handle cases where the extension does not respond
            checkExtensionTimeoutRef.current = setTimeout(() => {
                if (!extInstalled) {
                    console.log('TIMEOUT Catch');
                    setExtInstalled(false);
                }
            }, 1000); // 1 second timeout
        };

        checkExtensionInstalled();
    }, []); // Run only once

    return (
        <ExtensionContext.Provider value={{ extInstalled, sendMessageToExtension }}>
            {children}
        </ExtensionContext.Provider>
    );
};

// Custom hook to use the extension context
export const useExtension = () => useContext(ExtensionContext);
