import React, {useState, useEffect} from 'react';
import MarketplaceCard from './MarketplaceCard';
import PhotoPreview from './FormSimple/PhotoPreview';
import MarketplaceTitle from './FormSimple/MarketplaceTitle';
import MarketplaceDescription from './FormSimple/MarketplaceDescription';
import MarketplaceCategory from './MarketplaceCategory';
import PoshmarkItemDetails from './PoshmarkItemDetails';
import PoshmarkPricingDetails from './PoshmarkPricingDetails';

import { useUserAuth } from '../../context/UserAuthContext';
import { templateAPI } from '../../api';
import { updateMarketplaceStatus } from '../../utils/updateStatus';
import { validatePoshmark } from '../../utils/marketplace';

const CrossListPoshmark = ({formData, setFormData, ebayData, poshmarkData, setPoshmarkData, setActiveMarketplace, submitMarketplaceExt}) => {
    const { user } = useUserAuth();
    const [submitLoading, setSubmitLoading] = useState(false);
    const [alert, setAlert] = useState({ message: '', type: '' });
    const [poshmarkCategories, setPoshmarkCategories] = useState({});
    const [poshmarkSizeChartRegistry, setPoshmarkSizeChartRegistry] = useState({});
    const [categorySizeCharts, setCategorySizeCharts] = useState([]);

    const fetchPoshmarkCategories = async () => {
        try {
            const token = await user.getIdToken(true);
            const data = await templateAPI.getMarketplaceCategories(token, 'Poshmark')
            
            console.log('data - ', data);
            setPoshmarkCategories(data.categories);
            setPoshmarkSizeChartRegistry(data.sizeCharts)
        } catch (error) {
            console.error("Error fetching category data:", error);
        }
    };

    useEffect(() => {
        if (Object.keys(poshmarkCategories).length === 0) {
            fetchPoshmarkCategories();
        }
    }, []);

    useEffect(() => {
        if (poshmarkData?.category?.category && Object.keys(poshmarkSizeChartRegistry).length > 0) {
            const sizeCharts = poshmarkCategories?.[poshmarkData?.category?.department]?.[poshmarkData?.category?.category]?.['sizeCharts'];
            let sizeChartArray = [];

            sizeCharts?.forEach(sizeChart => {
                const chartDetails = poshmarkSizeChartRegistry?.[sizeChart];
                sizeChartArray.push(chartDetails)
            });

            setCategorySizeCharts(sizeChartArray);
        }
    }, [poshmarkData?.category?.category, poshmarkSizeChartRegistry]);


    const handleSubmitPoshmark = async () => {
        setSubmitLoading(true);
        setAlert({ message: '', type: '' }); 
        try {
            const validationResponse = validatePoshmark(poshmarkData);
            if (!validationResponse.validated) {
                setAlert({ message: validationResponse.alertMessage, type: validationResponse.alertType });
                setSubmitLoading(false); 
                return; 
            }
            setAlert({ message: 'Poshmark listing submitted in the background. Leave website open, but you can visit another page.', type: 'success' });
            updateMarketplaceStatus('Poshmark', 'submitting...', setFormData);

            const extSubmitResponse = await submitMarketplaceExt(poshmarkData, "poshmarkListingWindow");
            console.log('extSubmitResponse - ', extSubmitResponse);
            if(extSubmitResponse.newListing){
                //request Ids do not match, New Listing button used; do nothing
                return;
            }

            if(extSubmitResponse.error){
                setAlert({message: extSubmitResponse.alertMessage, type: extSubmitResponse.alertType});
                return;
            }

            const responseData = extSubmitResponse.data;
            console.log('responseData - ', responseData);

            if (extSubmitResponse.data?.marketplaceListingData) {

                let updates = {};
                if (responseData.marketplaceListingData._id) { updates.id = responseData.marketplaceListingData._id; }
                if (responseData.marketplaceListingData.generalListing){ updates.generalListing = responseData.marketplaceListingData.generalListing; }
                if (responseData.marketplaceListingData.status){ updates.status = responseData.marketplaceListingData.status; }
                if (responseData.marketplaceListingData.marketplaceListingId){ updates.marketplaceListingId = responseData.marketplaceListingData.marketplaceListingId; }
                
                if (Object.keys(updates).length > 0) {
                    setPoshmarkData(prevPoshmarkData => ({
                        ...prevPoshmarkData,
                        ...updates
                    }));
                }
            }

            if(!formData.id && responseData?.generalListingData?._id){
                setFormData(prevFormData => ({
                    ...prevFormData,
                    id: responseData.generalListingData._id
                }));
            }

            setAlert({message: extSubmitResponse.alertMessage, type: extSubmitResponse.alertType});
        } catch (err) {
            updateMarketplaceStatus('Poshmark', 'error', setFormData);
            setAlert({message: `Error in saving: ${err.response?.data?.message}`, type: 'danger'});
            return false;
        } finally {
            setSubmitLoading(false);
        }
    }


    return(
        <MarketplaceCard 
            formData={formData}
            setFormData={setFormData}
            ebayData={ebayData}
            marketplaceData={poshmarkData}
            setMarketplaceData={setPoshmarkData}
            marketplaceName="Poshmark"
            handleSubmitMarketplace={handleSubmitPoshmark}
            submitLoading={submitLoading}
            alert={alert}
            setAlert={setAlert}
            setActiveMarketplace={setActiveMarketplace}
        >
            <PhotoPreview
                formDataPictures={formData.pictures}
                marketplaceDataPictures={poshmarkData.pictures || []}
                setMarketplaceData={setPoshmarkData}
                marketplaceName="Poshmark"
                maxPhotos={16}
            />
            <MarketplaceTitle 
                marketplaceDataTitle={poshmarkData.title}
                setMarketplaceData={setPoshmarkData}
                marketplaceName="Poshmark"
            />
            <MarketplaceDescription
                marketplaceDataDescription={poshmarkData.description}
                setMarketplaceData={setPoshmarkData}
                marketplaceName="Poshmark"
            />
            <MarketplaceCategory 
                marketplaceDataCategory={poshmarkData.category}
                setMarketplaceData={setPoshmarkData}
                marketplaceName="Poshmark"
                marketplaceCategories={poshmarkCategories}
            />
            <PoshmarkItemDetails 
            // TODO: style tags
                poshmarkSize={poshmarkData.size}
                poshmarkSizeChart={poshmarkData.sizeChart}
                categorySizeCharts={categorySizeCharts}
                poshmarkCondition={poshmarkData.condition}
                poshmarkBrand={poshmarkData.brand}
                poshmarkColor={poshmarkData.color}
                setMarketplaceData={setPoshmarkData}
            />
            <PoshmarkPricingDetails
            //TODO: Discounted Shipping (Optional), Cost Price (optional), Availability 
                poshmarkPrice={poshmarkData.price}                
                poshmarkOriginalPrice={poshmarkData.originalPrice}
                poshmarkSKU={poshmarkData.sku}
                poshmarkUtils={poshmarkData.utils || {}}
                setMarketplaceData={setPoshmarkData}
            />


        </MarketplaceCard>
    )
}

export default CrossListPoshmark;